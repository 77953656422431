<div class="GamePageIntegrated">
  <casino-integrated-game-page
    [attr.session]="sessionID"
    [attr.userid]="userID"
    [attr.gameid]="gameID"
    [attr.checksession]="false"
    [attr.endpoint]="gamePageIntegratedConfig.endpoint"
    [attr.lang]="gamePageIntegratedConfig.lang"
    [attr.loginevent]="gamePageIntegratedConfig.loginEvent"
    [attr.registerevent]="gamePageIntegratedConfig.registerEvent"
    [attr.depositevent]="gamePageIntegratedConfig.depositEvent"
    [attr.clockformat]="gamePageIntegratedConfig.clockformat"
    [attr.haspanicbutton]="gamePageIntegratedConfig.haspanicbutton"
    [attr.gamebanneractive]="gamePageIntegratedConfig.gamebanneractive"
    [attr.clientstyling]="gamePageIntegratedConfig.clientstyling">
  </casino-integrated-game-page>
</div>
