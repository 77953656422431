import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { ActivatedRoute } from '@angular/router';

import {homePageTypeConfigs} from './home-page.type';

//@TODO I think we should change the main part - https://docs.npmjs.com/cli/v10/configuring-npm/package-json#main
//And instead of /dist/index.cjs.js which is needed for CommonJS modules, we can add the esm path there, since our main focus for the widgets should be the ES Module not CommonJS modules.
import '@everymatrix/general-slider-navigation/dist/general-slider-navigation/general-slider-navigation.esm.js';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';
import { customStyling } from '../../custom-styles/home-page';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  homePageConfigs:homePageTypeConfigs = {} as homePageTypeConfigs;

  constructor(
    private localizationService: LocalizationService,
    private route: ActivatedRoute
  ) {

      this.setConfig(environment);

      this.localizationService.currentLang().subscribe((selectedLang: string) => {
        this.homePageConfigs.lang = selectedLang;
      });
    }

   setConfig(config:any): void {
    const { app } = mergeConfigs(config, window.navigator.userAgent);

    this.homePageConfigs = {
      cmsEndpoint: app.cmsEndpoint,
      cmsEnv: app.cmsEnv,
      lang: app.language,
      clientstyling: customStyling,
      showSliderArrows: app.sliderNavigation.showSliderArrows,
      showSliderArrowsMobile: app.sliderNavigation.showSliderArrowsMobile,
      showMobileGrid: app.sliderNavigation.showMobileGrid,
      showNavigationSliderMobile: app.sliderNavigation.showNavigationSliderMobile,
      showNavigationSliderDesktop: app.sliderNavigation.showNavigationSliderDesktop,
      itemsPerPageDesktop: app.sliderNavigation.itemsPerPageDesktop,
      itemsPerPageMobile: app.sliderNavigation.itemsPerPageMobile,
      externalLinkActive: app.sliderNavigation.externalLinkActive,
      internalLinkActive: app.sliderNavigation.internalLinkActive
    };
  }

   ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'), false);
    });
  }

}
