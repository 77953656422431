import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../../services/localization.service';

import { ForgotPasswordConfig } from './forgot-password-component.type';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  key:string;
  forgotPasswordConfig: ForgotPasswordConfig = {} as ForgotPasswordConfig;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localizationService: LocalizationService
  ) {
    // This should be get via subscribe to changes - see email-verification
    this.setConfig(environment);
    this.key = this.route.snapshot.paramMap.get('key');

    this.router.navigate([this.forgotPasswordConfig.defaultRoute]);

    window.postMessage({
      type: 'OpenLoginRegisterModal',
      transition: 'ForgotPassword',
      key: this.key
    }, window.location.href);
  }

  setConfig(config:any): void {
    const { app } = mergeConfigs(config, window.navigator.userAgent);

    this.forgotPasswordConfig = {
      defaultRoute: app.defaultRoute
    };
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'));
    });
  }

}
