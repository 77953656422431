<div class="HomePageWrapper">
    <general-slider-navigation
        [attr.cms-endpoint]="homePageConfigs.cmsEndpoint"
        [attr.cms-env]="homePageConfigs.cmsEnv"
        [attr.language]="homePageConfigs.lang"
        [attr.clientstyling]='homePageConfigs.clientstyling'
        [attr.show-slider-arrows]="homePageConfigs.showSliderArrows"
        [attr.show-slider-arrows-mobile]="homePageConfigs.showSliderArrowsMobile"
        [attr.show-navigation-slider-mobile]="homePageConfigs.showNavigationSliderMobile"
        [attr.show-navigation-slider-desktop]="homePageConfigs.showNavigationSliderDesktop"
        [attr.show-mobile-grid]="homePageConfigs.showMobileGrid"
        [attr.items-per-page-desktop]="homePageConfigs.itemsPerPageDesktop"
        [attr.items-per-page-mobile]="homePageConfigs.itemsPerPageMobile"
        [attr.external-link-active]="homePageConfigs.externalLinkActive"
        [attr.internal-link-active]="homePageConfigs.internalLinkActive"
    ></general-slider-navigation>
</div>