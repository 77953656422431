import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../../services/localization.service';

import { LoginConfig } from './login-component.type';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginConfig: LoginConfig = {} as LoginConfig;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private localizationService: LocalizationService
  ) {
    this.setConfig(environment);

    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.loginConfig.language = selectedLang;
    });
  }

  setConfig(config:any): void {
    const { app } = mergeConfigs(config, window.navigator.userAgent);

    this.loginConfig = {
      language: app.language,
      defaultRoute: app.defaultRoute
    };
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'));
    });

    this.router.navigate([`/${this.loginConfig.language}${this.loginConfig.defaultRoute}`]);

    window.postMessage({
      type: 'OpenLoginRegisterModal',
      transition: 'Login'
    }, window.location.href);
  }
}
