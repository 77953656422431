import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private config:any = {};

  constructor(private toast: HotToastService) {
    this.setConfig(environment);
  }

  // @TODO config typescript model
  setConfig(config:any): void {
    const { app: { notifications } } = config;

    this.config = {
      position: notifications.position,
      dismissible: notifications.dismissible,
      autoClose: notifications.autoClose,
      style: notifications.style
    };
  }

  //@TODO data typescript model
  handle(data:any):void {
    switch (data.type) {
      case 'success':
        this.success(data.message, this.config);
        break;

      case 'error':
        this.error(data.message, this.config);
        break;

      case 'info':
        this.info(data.message, this.config);
        break;

      case 'warning':
        this.warning(data.message, this.config);
        break;

      case 'loading':
        this.loading(data.message, this.config);
        break;
    }
  }

  success(message:string, config?:any):void {
    const successConfig = config;

    this.toast.success(message, successConfig);
  }

  info(message:string, config?:any):void {
    const infoConfig = config;

    this.toast.info(message, infoConfig);
  }

  error(message:string, config?:any):void {
    const errorConfig = config;

    this.toast.error(message, errorConfig);
  }

  warning(message:string, config?:any):void {
    const warningConfig = config;

    this.toast.warning(message, warningConfig);
  }

  loading(message:string, config?:any):void {
    const loadingConfig = config;

    this.toast.loading(message, loadingConfig);
  }

  update():void {
    /*saveSettings
      .pipe(
        this.toast.observe({
          loading: 'Saving...',
          success: 'Settings saved!',
          error: 'Could not save.',
        })
      )
      .subscribe();*/
  }
}
