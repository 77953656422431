<div class="GamePage">
    <div class="BackButtonContainer">
      <div class="BackButton" (click)="this.goBack()">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><defs><style>.aaa{fill:var(--emfe-w-color-gray-50, #F9F8F8);}</style></defs><g transform="translate(-20 -158)">
          <g transform="translate(20 158)">
            <path class="aaa" d="M7.5,0,6.136,1.364,11.3,6.526H0V8.474H11.3L6.136,13.636,7.5,15,15,7.5Z" transform="translate(15 15) rotate(180)"/>
          </g></g>
        </svg>
        <span class="BackButtonText">{{ 'GamePage.goBack' | translate }}</span>
      </div>
    </div>
    <div *ngIf="isLoggedIn">
      <casino-game-page
        [attr.session]="sessionID"
        [attr.userid]="userID"
        [attr.gameid]="gameID"
        [attr.checksession]="false"
        [attr.endpoint]="gamePageConfig.endpoint"
        [attr.lang]="gamePageConfig.lang"
        [attr.loginevent]="gamePageConfig.loginEvent"
        [attr.registerevent]="gamePageConfig.registerEvent"
        [attr.depositevent]="gamePageConfig.depositEvent"
        [attr.favorites]="gamePageConfig.favorites"
        [attr.clockformat]="gamePageConfig.clockformat"
        [attr.haspanicbutton]="gamePageConfig.haspanicbutton"
        [attr.integratedgameframedesktop]="gamePageConfig.integratedgameframedesktop"
        [attr.integratedgameframemobile]="gamePageConfig.integratedgameframemobile"
        [attr.clientstyling]="gamePageConfig.clientstyling">
      </casino-game-page>
    </div>
    <div *ngIf="!isLoggedIn">
      <casino-game-page
        [attr.gameid]="gameID"
        [attr.checksession]="false"
        [attr.endpoint]="gamePageConfig.endpoint"
        [attr.lang]="gamePageConfig.lang"
        [attr.loginevent]="gamePageConfig.loginEvent"
        [attr.registerevent]="gamePageConfig.registerEvent"
        [attr.depositevent]="gamePageConfig.depositEvent"
        [attr.favorites]="gamePageConfig.favorites"
        [attr.clockformat]="gamePageConfig.clockformat"
        [attr.haspanicbutton]="gamePageConfig.haspanicbutton"
        [attr.integratedgameframedesktop]="gamePageConfig.integratedgameframedesktop"
        [attr.integratedgameframemobile]="gamePageConfig.integratedgameframemobile"
        [attr.clientstyling]="gamePageConfig.clientstyling">
      </casino-game-page>
    </div>
  </div>
