export const customStyling:string = `
  .SearchInput {
    background: #07072A;
    color: #FFFFFF;
  }

  svg path {
    stroke: #FFFFFF;
  }

  svg {
    fill: #FFFFFF;
  }

  .SearchCancelButton,
  .SearchMessage,
  .SearchInput::placeholder {
    color: #FFFFFF;
  }

  .SearchInput {
    border:1px solid #58586B;
  }

  .SearchResultsContainer {
    grid-template-rows: repeat(auto-fill, 115px);
    grid-auto-rows: 115px;
    grid-auto-flow: row dense;
    min-height: 100vh;
  }

  .HeaderButton {
    background-color: #07072A;
    color: #58586B;
    font-size: 18px;
  }

  .HeaderButton.Active {
    border-bottom: 2px solid #D0046C;
    color: #D0046C;
  }

  .CasinoCategories {
    padding: 10px 20px;
  }

  .CategoryTitle {
    font-size: 18px;
    color: #FFFFFF;
    right: 0;
  }

  .CategoryTextContainer p:first-child {
    color : #FFFFFF
  }

  .CategoryImage {
    height: 60px;
    width: 93px;
  }

  .CasinoProviders {
    padding: 10px 20px;
  }

  .ProviderTitle {
    font-size: 18px;
    color: #FFFFFF;
    right: 0;
  }

  .ProviderTextContainer p:first-child {
    color : #FFFFFF;
  }

  .ProviderImage {
    border: none;
    height: 60px;
    width: 93px;
  }
`;
