import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { SportsConfig } from './sports.type';

import { customStyling } from '../../custom-styles/sports';

import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';

import { environment } from './../../../environments/environment';
import { mergeConfigs, isMobile } from '../../../utils/utils';

import '@everymatrix/casino-betslip-indicator';

@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.scss']
})
export class SportsComponent implements OnInit, AfterViewInit {
  @ViewChild('iframe', { static: false }) sportsIframe: ElementRef;
  @HostListener('window:resize') onResize() {
    if (this.sportsConfig.version == 2 || this.sportsConfig.version == 3 && !isMobile(window.navigator.userAgent)) {
      this.setIframeHeight();
    }
  }


  // Using this to store the last value of iframe's height sent by OM since we're changing it to a fixed value when a modal opens inside the iframe.
  private lastOMHeight:string;

  isLoggedIn:boolean = false;
  sessionID:string = '';
  userID:string = '';
  private locationSubject: any;
  private authSubject: any;
  private routerSubject: any;

  sportsConfig:SportsConfig = {} as SportsConfig;
  sportsEndpoint:any;
  betslipActive: boolean = false;

  public height:string = this.sportsConfig.version == 3 ? '100vh' : '500px';

  constructor(
    private authService: AuthService,
    private localizationService: LocalizationService,
    private sanitizer:DomSanitizer,
    private router:Router,
    private route:ActivatedRoute,
    private location:Location
  ) {
    this.setConfig(environment);
    this.clearURL(new URL(this.sportsConfig.url));

    this.authSubject = this.authService.isLoggedIn().subscribe((loggedIn: boolean):void => {
      if (loggedIn) {
        const authSession = this.authService.getSessionID();
        if (this.sessionID != authSession) {
          this.sessionID = this.authService.getSessionID();
          this.isLoggedIn = true;

          const tempUrl = new URL(`${this.sportsConfig.url}${this.sportsConfig.lang}`);

          tempUrl.searchParams.append('currentSession', this.sessionID);

          this.clearURL(tempUrl);
        }
      } else {
        this.sessionID = '';
        this.isLoggedIn = false;

        if (this.sportsEndpoint.searchParams && this.sportsEndpoint.searchParams.get('currentSession')) {
          this.sportsEndpoint.searchParams.delete('currentSession');
        }
      }
  });

    this.locationSubject = this.localizationService.currentLang().subscribe((selectedLang: string):void => {
      this.sportsConfig.lang = selectedLang;

      const tempUrl = new URL(`${this.sportsConfig.url}${this.sportsConfig.lang}/`);

      if (this.sessionID.length > 0) {
        tempUrl.searchParams.append('currentSession', this.sessionID);
      }

      this.clearURL(tempUrl);
    });

    window.addEventListener('message', this.messageHandler, false);
  }

  messageHandler = (e:any): void => {
    if (e.data) {
      switch (e.data.type) {
        case 'OMFE:setIFrameHeight':
          this.height = `${e.data.payload}px`;
          break;

        case 'OMFE:locationChanged':
          if (e.data.payload.pathname) {
            this.location.replaceState(`${this.sportsConfig.lang}/sport/${e.data.payload.pathname}${e.data.payload.hash}`);

            if (e.data.payload.hash.length > 0) {
              window.scrollTo({
                top: e.data.payload.offset, // scroll so that the element is at the top of the view
                behavior: 'smooth' // smooth scroll
              });
            }
          }
          break;

        case 'OMFE:goToLogin':
          window.postMessage({ type: 'OpenLoginRegisterModal', login: true }, window.location.href);
          break;

        case 'OMFE:goToRegister':
          window.postMessage({ type: 'OpenLoginRegisterModal', login: false }, window.location.href);
          break;

        case 'OMFE:scrollTop':
          window.scrollTo({
            top: 0
          });
          break;

        case 'OMFE:iFrameHeight':
          if (isMobile(window.navigator.userAgent)) {
            this.height = `${e.data.payload}px`;
          } else {
            if (this.sportsConfig.version === 2) {
              this.height = `${e.data.payload}px`;
            } else if (this.sportsConfig.version === 3) {
              this.height = 'calc(100vh - 230px)';
            }
          }
          this.lastOMHeight = this.height;
          break;

        case 'OMFE:sessionTerminated':
          window.location.reload();
          break;

        case 'OMFE:showOverlay':
          // @TODO: treat alongside enable/disable scroll, add overlay class
          window.postMessage({ type: 'DisableScroll'}, window.location.href);
          if ( this.sportsConfig.mobileBottomNavigationEnabled ) {
            this.height= '82vh';
          } else {
            this.height = '90vh';
          }
          break;

        case 'OMFE:hideOverlay':
          // @TODO: treat alongside enable/disable scroll, remove overlay class
          window.postMessage({ type: 'EnableScroll'}, window.location.href);
          this.height = this.lastOMHeight;
          break;

        case 'OMFE:appReady':
          if (e.data) {
            // this.authService.setSessionID(this.sessionID);
          }
          break;

        case 'OMFE:sessionReady':
          if (e.data) {
            // this.authService.setSessionID(this.sessionID);
          }
          break;

        case 'OMFE:hideBetslip':
          this.betslipActive = false;
          break;
      }
    }
  };

  clearURL(url:URL): void {
    url.searchParams.append('basePath', `${window.location.origin}/${this.sportsConfig.lang}/sport`);

    this.sportsEndpoint = this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
  }

  setConfig(config:any): void {
    const { app, sports } = mergeConfigs(config, window.navigator.userAgent);

    this.sportsConfig = {
      url: sports.url,
      lang: app.language,
      mobileBottomNavigationEnabled: app.featureFlag.mobileBottomNavigationEnabled,
      version: sports.versionOfOMIframe,
      style: customStyling
    };
  }

  goToBetslip() {
    if (this.sportsConfig.version === 1) {
      this.sportsIframe.nativeElement.contentWindow.postMessage({
        type: 'GMCMS:goToBetslip'
      }, '*');
    }

    if (this.sportsConfig.version === 2 || this.sportsConfig.version === 3) {
      this.betslipActive = true;
      this.sportsIframe.nativeElement.contentWindow.postMessage({
        type: 'OP:showBetslip'
      }, '*');
    }
  }

  setIframeHeight(): void {
    // Take into account the header's height when setting the iframe's height.
    if (this.sportsIframe.nativeElement) {
      this.height = `calc(100vh - ${this.sportsIframe.nativeElement.offsetTop}px)`;
    }
  }

  setIframeUrl(url:string, urlList:string[]): void {
    if (this.isLoggedIn) {
      const tempSportsUrl = new URL(new URL(url + '/' + urlList.join('/')));

      tempSportsUrl.searchParams.append('currentSession', this.sessionID);

      this.clearURL(tempSportsUrl);
    } else {
      this.clearURL(new URL(url + '/' + urlList.join('/')));
    }
  }

  prepareSportsUrlList(url: string): string[] {
    let sportsUrlList = url.split('/').filter(truthyValue => truthyValue != '');

    sportsUrlList = sportsUrlList.map((item:any) => decodeURIComponent(item));
    sportsUrlList.splice(0, 2);

    return sportsUrlList;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params:any): void => {
      this.localizationService.setLang(params.get('languageCode'), false);
    });

    const sportsURL = this.sportsConfig.url + this.sportsConfig.lang;
    this.setIframeUrl(sportsURL, this.prepareSportsUrlList(this.router.url));

    this.routerSubject = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const tempUrl:string[] = event.url.split('/');

        tempUrl[1] = this.sportsConfig.lang;

        this.setIframeUrl(this.sportsConfig.url + this.sportsConfig.lang, this.prepareSportsUrlList(tempUrl.join('/')));
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.sportsConfig.version == 2 || this.sportsConfig.version == 3) {
      this.setIframeHeight();
    }
  }

  ngOnDestroy(): void {
    this.locationSubject.unsubscribe();
    this.authSubject.unsubscribe();
    this.routerSubject.unsubscribe();

    window.removeEventListener('message', this.messageHandler);
  }
}
