import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { LocalizationService } from './localization.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  // @TODO cmsConfig typescript model
  cmsConfig:any = {};
  routes:any;
  fetched:boolean = false;
  userRoles:string = '';
  device: string = '';
  datasources:any = {};
  datasourcesFetched:boolean = false;

  constructor(
    private http: HttpClient,
    private localizationService: LocalizationService,
    private authService: AuthService
  ) {
    this.setConfig(environment);
    this.device = this.getDeviceSegregation();

    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.cmsConfig.lang = selectedLang;
      this.datasourcesFetched = false;
      if (this.cmsConfig.fetchCMSDatasource) { // make the fetch call for the datasource only if the ff is on
        this.fetchDatasource();
      }
    });

    this.authService.getListOfRoles().subscribe((data:string) => {
      this.userRoles = data;
      this.fetchRoutes().subscribe((data) => {
        this.setRoutes(data);
      });
    });
  }

  getDevice(userAgent:string):string {
    if (userAgent.toLowerCase().match(/android/i)) {
      return 'Android';
    }

    if (userAgent.toLowerCase().match(/iphone/i)) {
      return 'iPhone';
    }

    if (userAgent.toLowerCase().match(/ipad|ipod/i)) {
      return 'iPad';
    }

    return 'PC';
  }

  getDeviceSegregation():string {
    const userAgent = window.navigator.userAgent;
    const device = this.getDevice(userAgent);

    if (device === 'PC') {
      return 'dk';
    } else if (device === 'iPad' || device === 'iPhone') {
      return 'ios';
    } else {
      return 'mtWeb';
    }
  }

  routesFetched(): boolean {
    return this.fetched;
  }


  setRoutes(routes:any):void {
    this.fetched = true;
    this.routes = routes;
  }

  // @TODO routes type config
  fetchRoutes(): Observable<HttpResponse<any>> {
    if (this.userRoles) {
      return this.http.get<any>(`${this.cmsConfig.cmsEndpoint}/${this.cmsConfig.lang}/routes?userRoles=${this.userRoles}&device=${this.device}`);
    } else {
      return this.http.get<any>(`${this.cmsConfig.cmsEndpoint}/${this.cmsConfig.lang}/routes?device=${this.device}`);
    }
  }

  fetchDatasource(): Observable<HttpResponse<any>> {
    if (this.userRoles) {
      return this.http.get<any>(`${this.cmsConfig.cmsEndpoint}/${this.cmsConfig.lang}/data-sources?env=${this.cmsConfig.production ? 'prod' : 'stage'}&userRoles=${this.userRoles}&device=${this.device}`);
    } else {
      return this.http.get<any>(`${this.cmsConfig.cmsEndpoint}/${this.cmsConfig.lang}/data-sources?env=${this.cmsConfig.production ? 'prod' : 'stage'}&device=${this.device}`);
    }
  }

  getRoutes(): any[] {
    return this.routes;
  }

  getDatasource(location:string): Promise<any[] | string> {
    return new Promise((resolve, reject) => {
      if (this.datasourcesFetched) {
        if (this.datasources.newDataSources[location]) {
          resolve(this.datasources.newDataSources[location]);
        } else {
          reject(new Error('No datasource found'));
        }
      } else {
        this.fetchDatasource().subscribe((data) => {
          this.datasources = data;

          this.datasourcesFetched = true;
          if (this.datasources?.newDataSources) {
            resolve(this.datasources.newDataSources[location]);
          } else {
            reject(new Error('No datasource found'));
          }
        });
      }
    });
  }

  getCmsData(cmsendpoint:string, lang: string, env: string): Observable<any> {
    const device = this.getDeviceSegregation();
    const url = new URL(`${cmsendpoint}/${lang}/login-register-content?env=${env}`);
    url.searchParams.append('device', device);
    return this.http.get(url.href);
  }

  setDatasources(datasources:any):void {
    this.datasources = datasources;
  }

  // @TODO page type config
  getPage(pageId:string): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.cmsConfig.cmsEndpoint}/${this.cmsConfig.lang}/page/${pageId}?device=${this.device}`);
  }

  setConfig(config:any): void {
    const { app } = config;

    this.cmsConfig = {
      cmsEndpoint: app.cmsEndpoint,
      production: config.production,
      fetchCMSDatasource: app.fetchCMSDatasource
    };
  }
}
