import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { LocalizationService } from './localization.service';

@Injectable({
  providedIn: 'root'
})
export class NotFoundResolverService {
  language:string = 'en';

  constructor(
    private router: Router,
    private localizationService: LocalizationService
  ) {
    this.setConfig(environment);

    this.localizationService.currentLang().subscribe((selectedLang:string) => {
      this.language = selectedLang;
    });
  }

  setConfig(config: any): void {
    const { app } = config;

    this.language = app.language;
  }

  canActivate(): boolean {
    this.router.navigate([`/${this.language}/404-not-found`]);

    return false;
  }
}
