import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { TournamentsLobbyConfig } from './tournaments-lobby.type';

import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

import { customStyling } from '../../custom-styles/tournaments-lobby';

import '@everymatrix/casino-tournaments-controller';

@Component({
  selector: 'app-tournaments-lobby',
  templateUrl: './tournaments-lobby.component.html',
  styleUrls: ['./tournaments-lobby.component.scss']
})
export class TournamentsLobbyComponent implements OnInit {
  private sessionData:any;

  isLoggedIn:boolean = false;
  sessionID:string = '';
  userID:string = '';
  currency:string = '';

  toursLobbyConfig:TournamentsLobbyConfig = {} as TournamentsLobbyConfig;
  currentRoute: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private localizationService: LocalizationService
  ) {
    this.setConfig(environment);

    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.toursLobbyConfig.lang = selectedLang;
    });

    this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd) {
        const filters = event.url.split('/')[3];

        this.toursLobbyConfig.filters = filters !== undefined ? filters : null;
      }
    });

    this.authService.isLoggedIn().subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        this.sessionData = this.authService.getSession();

        this.sessionID = this.sessionData.Guid;
        this.userID = this.sessionData.UserID;
        this.currency = this.sessionData.Currency;

        this.isLoggedIn = true;
      } else {
        this.sessionID = '';
        this.userID = '';

        this.isLoggedIn = false;
      }
    });

    window.addEventListener('message', this.messageHandler);
  }

  setConfig(config:any): void {
    const { app, tournamentsLobby } = mergeConfigs(config, window.navigator.userAgent);

    this.toursLobbyConfig = {
      endpoint: app.endpointCE,
      lang: app.language,
      showMore: tournamentsLobby.showMore,
      containermaxwidth: tournamentsLobby.containermaxwidth,
      cmsEndpoint: tournamentsLobby.cmsendpoint,
      numberOfTournaments: tournamentsLobby.numberOfTournaments,
      filters: this.toursLobbyConfig.filters || tournamentsLobby.filters,
      loginEvent: tournamentsLobby.loginEvent,
      registerEvent: tournamentsLobby.registerEvent,
      keepBrowserContext: tournamentsLobby.keepBrowserContext,
      clientstyling: customStyling
    };
  }

  messageHandler = (e:any): void => {
    if (e.data && e.data.type == 'TournamentsFiltersSelected') {
      this.toursLobbyConfig.filters = e.data.filters;

      this.router.navigate([`/${this.toursLobbyConfig.lang}/tournaments/${e.data.filters}`]);
    }
  };

  ngOnInit(): void {
    //Banner matrix fix when changing page from profile section
    window.postMessage({
      type: 'BMFE:sliderRefresh',
      payload: {}
    }, '*');

    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'), false);
    });

    if (this.toursLobbyConfig.filters) {
      // this.router.navigate([`/${this.toursLobbyConfig.lang}/tournaments/${this.toursLobbyConfig.filters}`]);
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.messageHandler);
  }

}
