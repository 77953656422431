import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isMobile } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class MobileRouteService {

  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const userAgent = window.navigator.userAgent;

    if (!isMobile(userAgent)) {
      this.router.navigate(['/404-not-found']);
      return false;
    }

    return true;
  }
}
