import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { CasinoLobbyComponent } from './states/casino-lobby/casino-lobby.component';
import { LiveLobbyComponent } from './states/live-lobby/live-lobby.component';
import { VirtualsSportsLobbyComponent } from './states/virtuals-sports-lobby/virtuals-sports-lobby.component';
import { TournamentsLobbyComponent } from './states/tournaments-lobby/tournaments-lobby.component';
import { TournamentsMyLobbyComponent } from './states/tournaments-my-lobby/tournaments-my-lobby.component';
import { TournamentPageComponent } from './states/tournament-page/tournament-page.component';
import { PlayerProfileComponent } from './states/player-profile/player-profile.component';
import { PromotionsComponent } from './states/promotions/promotions.component';
import { SportsComponent } from './states/sports/sports.component';
import { NotFoundComponent } from './states/not-found/not-found.component';
import { ServerErrorComponent } from './states/server-error/server-error.component';
import { NotAuthorizedComponent } from './states/not-authorized/not-authorized.component';
import { EmailVerificationComponent } from './states/email-verification/email-verification.component';
import { LoginComponent } from './states/login/login.component';
import { RegisterComponent } from './states/register/register.component';
import { ForgotPasswordComponent } from './states/forgot-password/forgot-password.component';
import { GamePageComponent } from './states/game-page/game-page.component';
import { GamePageIntegratedComponent } from './states/game-page-integrated/game-page-integrated.component';
import { CategoriesComponent } from './states/categories/categories.component';
import { WheelOfFortuneComponent } from './states/wheel-of-fortune/wheel-of-fortune.component';
import { HomePageComponent } from './states/home-page/home-page.component';

import { AuthGuardService } from './services/auth-guard.service';
import { CmsRoutesResolverService } from './services/cms-routes-resolver.service';
import { RouteResolverService } from './services/route-resolver.service';
import { NotFoundResolverService } from './services/not-found-resolver-service.service';

import { environment } from './../environments/environment';
import { MobileRouteService } from './services/mobile-route.service';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: `${environment.app.language}${environment.app.defaultRoute}` },
  { path: ':languageCode/play-game/:gameSlug', canActivate: [ RouteResolverService ], component: GamePageComponent },
  { path: ':languageCode/game-page/:gameID', canActivate: [ RouteResolverService ], component: GamePageIntegratedComponent },
  { path: ':languageCode/casino', canActivate: [ RouteResolverService ], component: CasinoLobbyComponent, children: [
    { path: '**', canActivate: [ RouteResolverService ], component: CasinoLobbyComponent }
  ] },
  { path: ':languageCode/live-casino', canActivate: [ RouteResolverService ], component: LiveLobbyComponent, children: [
    { path: '**', canActivate: [ RouteResolverService ], component: LiveLobbyComponent }
  ] },
  { path: ':languageCode/virtuals', canActivate: [ RouteResolverService ], component: VirtualsSportsLobbyComponent, children: [
    { path: '**', canActivate: [ RouteResolverService ], component: VirtualsSportsLobbyComponent }
  ] },
  { path: ':languageCode/tournaments',  component: TournamentsLobbyComponent, canActivate: [ RouteResolverService ], children: [
    { path: ':filters', component: TournamentsLobbyComponent, canActivate: [ RouteResolverService ]}
  ]},
  { path: ':languageCode/mytournaments', component: TournamentsMyLobbyComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
  { path: ':languageCode/tournament/:tourID', component: TournamentPageComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
  { path: ':languageCode/account', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ], children: [
    { path: 'profile-info', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'account-verification', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ]},
    { path: 'change-password', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'gaming-history', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'bonus-history', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'active-bonuses', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'gaming-limits', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'reality-check', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'timeout', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'self-exclusion', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'account-closure', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'deposit', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'withdrawal', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'pending-withdrawals', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'transaction-history', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'betting-history', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'limits', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'transactions-history', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'cashier-deposit', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
    { path: 'cashier-withdrawal', component: PlayerProfileComponent, canActivate: [ RouteResolverService, AuthGuardService ] }
  ]},
  { path: ':languageCode/email-verification/:key', canActivate: [ RouteResolverService ], component: EmailVerificationComponent },
  { path: ':languageCode/forgot-password/:key', canActivate: [ RouteResolverService ],  component: ForgotPasswordComponent },
  { path: ':languageCode/promotions', canActivate: [ RouteResolverService ], component: PromotionsComponent, children: [
    { path: ':promotionId', component: PromotionsComponent, canActivate: [ RouteResolverService ] }
  ]},
  { path: ':languageCode/sports', pathMatch: 'full', redirectTo: 'en/sport' },
  { path: ':languageCode/sport', canActivate: [ RouteResolverService ], component: SportsComponent, children: [
    { path: '**', canActivate: [ RouteResolverService ], component: SportsComponent }
  ] },
  { path: ':languageCode/home', canActivate: [ RouteResolverService ], component: HomePageComponent},
  { path: ':languageCode/404-not-found', component: NotFoundComponent },
  { path: ':languageCode/500-server-error', component: ServerErrorComponent },
  { path: ':languageCode/403-not-authorized', component: NotAuthorizedComponent },
  { path: ':languageCode/login', canActivate: [ RouteResolverService ], component: LoginComponent },
  { path: ':languageCode/register', canActivate: [ RouteResolverService ], component: RegisterComponent },
  { path: ':languageCode/page', canLoad: [ CmsRoutesResolverService ], loadChildren: () => import('./static.module').then(mod => mod.StaticModule) },
  { path: ':languageCode/**', redirectTo: '404-not-found' },
  { path: ':languageCode/categories' , component: CategoriesComponent, canActivate: [ RouteResolverService, MobileRouteService ]},
  { path: ':languageCode/wheel-of-fortune' , component: WheelOfFortuneComponent, canActivate: [ RouteResolverService, AuthGuardService ] },
  { path: '**', canActivate: [NotFoundResolverService] ,component: NotFoundComponent }
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'enabled'
};
@NgModule({
  imports: [ RouterModule.forRoot(routes, routerOptions) ],
  exports: [ RouterModule ],
  providers: [
    AuthGuardService,
    CmsRoutesResolverService,
    RouteResolverService,
    MobileRouteService
  ]
})
export class AppRoutingModule {

}
