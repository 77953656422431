<casino-tournaments-controller
  [attr.session]="sessionID"
  [attr.userid]="userID"
  [attr.currency]="currency"
  [attr.filters]="toursLobbyConfig.filters"
  [attr.endpoint]="toursLobbyConfig.endpoint"
  [attr.cmsendpoint]="toursLobbyConfig.cmsEndpoint"
  [attr.numberoftournaments]="toursLobbyConfig.numberOfTournaments"
  [attr.showmorestep]="toursLobbyConfig.showMore"
  [attr.lang]="toursLobbyConfig.lang"
  [attr.loginevent]="toursLobbyConfig.loginEvent"
  [attr.registerevent]="toursLobbyConfig.registerEvent"
  [attr.containermaxwidth]="toursLobbyConfig.containermaxwidth"
  [attr.keepbrowsercontext]="toursLobbyConfig.keepBrowserContext"
  [attr.clientstyling]="toursLobbyConfig.clientstyling"
></casino-tournaments-controller>
