<div>
  <div
  *ngIf="show" @fadeIn @fadeOut
  class="LoginModalWindow"
  [ngClass]="{
    'LoginModalMobileWindow': mobileView
  }"
  class="LoginModalWindow"
>
  <div class="LoginModalContainer" part="LoginModalContainer">
    <div class="LoginRegisterPageContainer">
      <ng-container *ngIf="isLoading; else elseBlock">
        <div class="LoaderWrapper">
          <div class="ModalLoader"></div>
        </div>
      </ng-container>
      <ng-template #elseBlock>
        <div class="LoginModalContent" [ngClass]="{ 'LoginModalMobileContent': mobileView }">
          <section class="LoginHeaderSection" [ngClass]="{ 'LoginHeaderSectionMobile': mobileView }"
            [style.background-image]="mobileView ? 'url(' + sectionHeaderBackgroundSrc + ')' : ''">
            <button class="SignInButton" [ngClass]="{ 'ActiveButton': loginactive }" (click)="login()">
              {{ 'LoginRegisterController.login' | translate }}
            </button>
            <button class="RegisterButton" [ngClass]="{ 'ActiveButton': !loginactive }" (click)="register()">
              {{ 'LoginRegisterController.register' | translate }}
            </button>
          </section>

          <section class="LoginContentSection">
            <ng-container *ngIf="loginactive; else registerFormBlock">
              <ng-container *ngIf="forgotPasswordActive; else loginFormBlock">
                <general-player-forgot-password-form
                  class="ComponentClass"
                  [attr.endpoint]="LoginRegisterConfig.endpoint"
                  [attr.forgotpasswordkey]="forgotpasswordkey"
                  [attr.captchakey]="LoginRegisterConfig.captchakey"
                  [attr.lang]="LoginRegisterConfig.lang"
                  [attr.simplepasswordvalidation]="LoginRegisterConfig.simplepasswordvalidation"
                  [attr.clientstyling]="LoginRegisterConfig.clientStyling"
                  [attr.savecredentials]="LoginRegisterConfig.savecredentials"
                ></general-player-forgot-password-form>
                <div *ngIf="!mobileView" class="ForgotPasswordFormImage" [style.background-image]="cmsLoginData ? 'url(' + cmsLoginData.mobileImg + ')' : ''" style="background-repeat: no-repeat;"></div>
              </ng-container>
              <ng-template #loginFormBlock>
                <div *ngIf="!LoginRegisterConfig.legacyLogin; else gmcore"> 
                  <user-login
                    class="ComponentClass"
                    [attr.endpoint]="LoginRegisterConfig.endpoint"
                    [attr.lang]="LoginRegisterConfig.lang"
                    [attr.user-email-regex]="LoginRegisterConfig.userEmailRegex"
                    [attr.user-email-regex-options]="LoginRegisterConfig.userEmailRegexOptions"
                    [attr.password-reset]="LoginRegisterConfig.passwordReset"
                    [attr.password-regex]="LoginRegisterConfig.passwordRegex"
                    [attr.password-regex-options]="LoginRegisterConfig.passwordRegexOptions"
                    [attr.client-styling]="LoginRegisterConfig.clientStyling"
                  ></user-login>
                </div>
                <ng-template #gmcore>
                  <general-player-login-form 
                    class="ComponentClass"
                    [attr.endpoint]="LoginRegisterConfig.endpoint"
                    [attr.smsverification]="LoginRegisterConfig.smsverification"
                    [attr.captchakey]="LoginRegisterConfig.captchakey"
                    [attr.lang]="LoginRegisterConfig.lang"
                    [attr.simplepasswordvalidation]="LoginRegisterConfig.simplepasswordvalidation"
                    [attr.clientstyling]="LoginRegisterConfig.clientStyling"
                    [attr.savecredentials]="LoginRegisterConfig.savecredentials"
                  ></general-player-login-form>
                </ng-template>
                <div *ngIf="!mobileView" class="LoginFormImage" [style.background-image]="cmsLoginData ? 'url(' + cmsLoginData.mobileImg + ')' : ''" style="background-repeat: no-repeat;"></div>
              </ng-template>
            </ng-container>
            <ng-template #registerFormBlock>
              <ng-container *ngIf="LoginRegisterConfig.legacyRegister; else registerV1Block">
                <div *ngIf="!mobileView" class="RegisterFormImage" [style.background-image]="cmsRegisterData ? 'url(' + cmsRegisterData.mobileImg + ')' : ''" style="background-repeat: no-repeat;"></div>
                <general-player-register-form
                  class="ComponentClass"
                  [attr.endpoint]="LoginRegisterConfig.endpoint"
                  [attr.smsverification]="LoginRegisterConfig.smsverification"
                  [attr.cmsendpoint]="LoginRegisterConfig.cmsEndpoint"
                  [attr.env]="LoginRegisterConfig.env"
                  [attr.captchakey]="LoginRegisterConfig.captchakey"
                  [attr.lang]="LoginRegisterConfig.lang"
                  [attr.licenseyears]="LoginRegisterConfig.licenseyears"
                  [attr.disabledfields]="LoginRegisterConfig.registerDisabledFields"
                  [attr.defaultoptions]="LoginRegisterConfig.defaultOptions"
                  [attr.custominputtextswitch]="LoginRegisterConfig.customInputTextSwitch"
                  [attr.simplepasswordvalidation]="LoginRegisterConfig.simplepasswordvalidation"
                  [attr.userroles]="userRoles"
                  [attr.clientstyling]="LoginRegisterConfig.clientStyling"
                  [attr.savecredentials]="LoginRegisterConfig.savecredentials"
                  [attr.trackedanalyticsdata]="btag"
                ></general-player-register-form>
              </ng-container>

              <ng-template #registerV1Block>
                <general-registration
                  class="GeneralRegistration"
                  [attr.endpoint]="LoginRegisterConfig.endpoint"
                  [attr.lang]="LoginRegisterConfig.lang"
                  [attr.client-styling]="LoginRegisterConfig.clientStyling"
                  [attr.date-format]="LoginRegisterConfig.dateformat"
                  [attr.button-inside-form]="LoginRegisterConfig.buttoninsideform"
                  [attr.btag]="LoginRegisterConfig.btag"
                ></general-registration>
              </ng-template>
            </ng-template>
          </section>
        </div>
      </ng-template>
    </div>
    <span [ngClass]="{
      'ModalCloseBtn': !mobileView,
      'ModalCloseBtnMobile': mobileView
    }"
    (click)="close()" role="button">
      <slot name="close">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </slot>
    </span>
  </div>
</div>
</div>
