<div *ngIf="isLoggedIn">
  <casino-lobby
    [attr.session]="sessionID"
    [attr.userid]="userID"
    [attr.endpoint]="casinoLobbyConfig.endpoint"
    [attr.datasource]="casinoLobbyConfig.datasource"
    [attr.winnersdatasources]="casinoLobbyConfig.winnersdatasources"
    [attr.userroles]="userRoles"
    [attr.lang]="casinoLobbyConfig.lang"
    [attr.currency]="casinoLobbyConfig.currency"
    [attr.positionjackpotbanners]="casinoLobbyConfig.jackpotBannersWidget.positionjackpotbanners"
    [attr.caticon0]="casinoLobbyConfig.jackpotBannersWidget.caticon0"
    [attr.caticon1]="casinoLobbyConfig.jackpotBannersWidget.caticon1"
    [attr.caticon2]="casinoLobbyConfig.jackpotBannersWidget.caticon2"
    [attr.caticon3]="casinoLobbyConfig.jackpotBannersWidget.caticon3"
    [attr.backgroundsrc]="casinoLobbyConfig.jackpotBannersWidget.backgroundsrc"
    [attr.backgroundsrcmobile]="casinoLobbyConfig.jackpotBannersWidget.backgroundsrcmobile"
    [attr.titlelogopath]="casinoLobbyConfig.jackpotBannersWidget.titlelogopath"
    [attr.iconlabels]="casinoLobbyConfig.jackpotBannersWidget.iconlabels"
    [attr.visualstructure]="casinoLobbyConfig.jackpotBannersWidget.visualstructure"
    [attr.mostplayed]="casinoLobbyConfig.mostPlayed"
    [attr.mostplayedrounds]="casinoLobbyConfig.mostplayedrounds"
    [attr.lastplayed]="casinoLobbyConfig.lastplayed"
    [attr.lastplayedperiod]="casinoLobbyConfig.lastplayedperiod"
    [attr.mostplayedperiod]="casinoLobbyConfig.mostplayedperiod"
    [attr.favorites]="casinoLobbyConfig.favorites"
    [attr.loginevent]="casinoLobbyConfig.loginEvent"
    [attr.registerevent]="casinoLobbyConfig.registerEvent"
    [attr.depositevent]="casinoLobbyConfig.depositEvent"
    [attr.lobbyid]="casinoLobbyConfig.lobbyID"
    [attr.containermaxwidth]="casinoLobbyConfig.containermaxwidth"
    [attr.clientstyling]='casinoLobbyConfig.clientstyling'
    [attr.haspanicbutton]="casinoLobbyConfig.haspanicbutton"
    [attr.collectionprovidersgamenames]="casinoLobbyConfig.collectionprovidersgamenames"
    [attr.clockformat]="casinoLobbyConfig.clockformat"
    [attr.activecategory]="casinoLobbyConfig.activecategory"
    [attr.actionevent]="casinoLobbyConfig.actionevent"
    [attr.gamemodal]="casinoLobbyConfig.gamemodal"
    [attr.playrandomgame]="casinoLobbyConfig.randomgame"
    [attr.randombuttonicon]="casinoLobbyConfig.randombuttonicon"
    [attr.playforfun]="casinoLobbyConfig.playforfun"
    [attr.visiblegames]="casinoLobbyConfig.visiblegames"
    [attr.integratedgameframedesktop]="casinoLobbyConfig.integratedgameframedesktop"
    [attr.integratedgameframemobile]="casinoLobbyConfig.integratedgameframemobile"
    [attr.gamepagemodalurl]="casinoLobbyConfig.gamepagemodalurl"
    [attr.showsubgroups]="casinoLobbyConfig.showsubgroups"
    [attr.addfilterselector]="casinoLobbyConfig.addfilterselector"
    [attr.addsortingselector]="casinoLobbyConfig.addsortingselector"
    [attr.filterbylogo]="casinoLobbyConfig.filterbylogo"
    [attr.positionwinnerswidget]="casinoLobbyConfig.winnersWidget.positionwinnerswidget"
    [attr.periodrecent]="casinoLobbyConfig.winnersWidget.periodrecent"
    [attr.periodtop]="casinoLobbyConfig.winnersWidget.periodtop"
    [attr.numberusersrecent]="casinoLobbyConfig.winnersWidget.numberusersrecent"
    [attr.numberuserstop]="casinoLobbyConfig.winnersWidget.numberuserstop"
    [attr.amountlimit]="casinoLobbyConfig.winnersWidget.amountlimit"
    [attr.vendorcategory]="casinoLobbyConfig.winnersWidget.vendorcategory"
    [attr.isrecentavailable]="casinoLobbyConfig.winnersWidget.isrecentavailable"
    [attr.istopavailable]="casinoLobbyConfig.winnersWidget.istopavailable"
    [attr.defaultcurrency]="casinoLobbyConfig.winnersWidget.defaultcurrency"
    [attr.enableautoscroll]="casinoLobbyConfig.winnersWidget.enableautoscroll"
    [attr.tabsorder]="casinoLobbyConfig.winnersWidget.tabsorder"
    [attr.positioncollections]="casinoLobbyConfig.collectionsProvidersWidget.positioncollections"
    [attr.type]="casinoLobbyConfig.collectionsProvidersWidget.type"
    [attr.titletype]="casinoLobbyConfig.collectionsProvidersWidget.titletype"
    [attr.filteredcategories]="casinoLobbyConfig.filteredcategories"
    [attr.filteredrandomgamecategories]="casinoLobbyConfig.filteredrandomgamecategories"
    [attr.positioncasinocalendar]="casinoLobbyConfig.positioncasinocalendar"
    [attr.casinogamesgridslider]="casinoLobbyConfig.casinogamesgridslider"
    ></casino-lobby>
  </div>
  <div *ngIf="!isLoggedIn">
    <casino-lobby
    [attr.endpoint]="casinoLobbyConfig.endpoint"
    [attr.datasource]="casinoLobbyConfig.datasource"
    [attr.winnersdatasources]="casinoLobbyConfig.winnersdatasources"
    [attr.lang]="casinoLobbyConfig.lang"
    [attr.userroles]="userRoles"
    [attr.currency]="casinoLobbyConfig.currency"
    [attr.positionjackpotbanners]="casinoLobbyConfig.jackpotBannersWidget.positionjackpotbanners"
    [attr.caticon0]="casinoLobbyConfig.jackpotBannersWidget.caticon0"
    [attr.caticon1]="casinoLobbyConfig.jackpotBannersWidget.caticon1"
    [attr.caticon2]="casinoLobbyConfig.jackpotBannersWidget.caticon2"
    [attr.caticon3]="casinoLobbyConfig.jackpotBannersWidget.caticon3"
    [attr.backgroundsrc]="casinoLobbyConfig.jackpotBannersWidget.backgroundsrc"
    [attr.backgroundsrcmobile]="casinoLobbyConfig.jackpotBannersWidget.backgroundsrcmobile"
    [attr.titlelogopath]="casinoLobbyConfig.jackpotBannersWidget.titlelogopath"
    [attr.iconlabels]="casinoLobbyConfig.jackpotBannersWidget.iconlabels"
    [attr.visualstructure]="casinoLobbyConfig.jackpotBannersWidget.visualstructure"
    [attr.mostplayed]="casinoLobbyConfig.mostPlayed"
    [attr.mostplayedrounds]="casinoLobbyConfig.mostplayedrounds"
    [attr.favorites]="casinoLobbyConfig.favorites"
    [attr.loginevent]="casinoLobbyConfig.loginEvent"
    [attr.registerevent]="casinoLobbyConfig.registerEvent"
    [attr.collectionprovidersgamenames]="casinoLobbyConfig.collectionprovidersgamenames"
    [attr.depositevent]="casinoLobbyConfig.depositEvent"
    [attr.lobbyid]="casinoLobbyConfig.lobbyID"
    [attr.clientstyling]='casinoLobbyConfig.clientstyling'
    [attr.containermaxwidth]="casinoLobbyConfig.containermaxwidth"
    [attr.haspanicbutton]="casinoLobbyConfig.haspanicbutton"
    [attr.clockformat]="casinoLobbyConfig.clockformat"
    [attr.activecategory]="casinoLobbyConfig.activecategory"
    [attr.actionevent]="casinoLobbyConfig.actionevent"
    [attr.gamemodal]="casinoLobbyConfig.gamemodal"
    [attr.playrandomgame]="casinoLobbyConfig.randomgame"
    [attr.playforfun]="casinoLobbyConfig.playforfun"
    [attr.visiblegames]="casinoLobbyConfig.visiblegames"
    [attr.integratedgameframedesktop]="casinoLobbyConfig.integratedgameframedesktop"
    [attr.integratedgameframemobile]="casinoLobbyConfig.integratedgameframemobile"
    [attr.gamepagemodalurl]="casinoLobbyConfig.gamepagemodalurl"
    [attr.showsubgroups]="casinoLobbyConfig.showsubgroups"
    [attr.addfilterselector]="casinoLobbyConfig.addfilterselector"
    [attr.addsortingselector]="casinoLobbyConfig.addsortingselector"
    [attr.filterbylogo]="casinoLobbyConfig.filterbylogo"
    [attr.positionwinnerswidget]="casinoLobbyConfig.winnersWidget.positionwinnerswidget"
    [attr.periodrecent]="casinoLobbyConfig.winnersWidget.periodrecent"
    [attr.periodtop]="casinoLobbyConfig.winnersWidget.periodtop"
    [attr.numberusersrecent]="casinoLobbyConfig.winnersWidget.numberusersrecent"
    [attr.numberuserstop]="casinoLobbyConfig.winnersWidget.numberuserstop"
    [attr.amountlimit]="casinoLobbyConfig.winnersWidget.amountlimit"
    [attr.vendorcategory]="casinoLobbyConfig.winnersWidget.vendorcategory"
    [attr.istopavailable]="casinoLobbyConfig.winnersWidget.istopavailable"
    [attr.isrecentavailable]="casinoLobbyConfig.winnersWidget.isrecentavailable"
    [attr.defaultcurrency]="casinoLobbyConfig.winnersWidget.defaultcurrency"
    [attr.enableautoscroll]="casinoLobbyConfig.winnersWidget.enableautoscroll"
    [attr.tabsorder]="casinoLobbyConfig.winnersWidget.tabsorder"
    [attr.positioncollections]="casinoLobbyConfig.collectionsProvidersWidget.positioncollections"
    [attr.type]="casinoLobbyConfig.collectionsProvidersWidget.type"
    [attr.titletype]="casinoLobbyConfig.collectionsProvidersWidget.titletype"
    [attr.filteredcategories]="casinoLobbyConfig.filteredcategories"
    [attr.filteredrandomgamecategories]="casinoLobbyConfig.filteredrandomgamecategories"
    [attr.positioncasinocalendar]="casinoLobbyConfig.positioncasinocalendar"
    [attr.casinogamesgridslider]="casinoLobbyConfig.casinogamesgridslider"
    ></casino-lobby>
  </div>