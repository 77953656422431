import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../../services/localization.service';

import { EmailVerificationConfig } from './email-verification.type';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

import { customStyling } from '../../custom-styles/email-verification';

import '@everymatrix/player-email-verification';
import '@everymatrix/casino-mail-verification';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  emailVerificationConfig: EmailVerificationConfig = {} as EmailVerificationConfig;
  success:boolean = false;
  isLoading:boolean = true;
  lang:string = 'en';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localizationService: LocalizationService
  ) {
    this.setConfig(environment);

    this.localizationService.currentLang().subscribe((selectedLang: string):void => {
      this.lang = selectedLang;
    });
  }

  messageHandler(e:any): void {
    if (e.data) {
      switch (e.data.type) {
        case 'ActivatePlayerResponseFailed':
          this.success = false;
          this.isLoading = false;
          break;

        case 'ActivatePlayerResponse':
          if (e.data.data.error) {
            this.success = false;
          } else {
            this.success = true;
          }

          this.isLoading = false;
          break;
      }
    }
  }

  ngOnInit(): void {
    window.addEventListener('message', this.messageHandler.bind(this), false);

    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'));
      this.emailVerificationConfig.key = params.get('key');
    });
  }

  goToHome() {
    this.router.navigate([this.emailVerificationConfig.defaultRoute]);
  }

  setConfig(config:any): void {
    const { app } = mergeConfigs(config, window.navigator.userAgent);
    
    this.emailVerificationConfig = {
      endpoint: app.endpoint,
      defaultRoute: app.defaultRoute,
      clientstyling: customStyling
    };
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.messageHandler);
  }
}
