import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';
import { isNative, mergeConfigs, isMobile } from '../../../utils/utils';
import { LoginRegisterConfig } from 'src/app/states/login-register-controller/login-register-controller.type';
import { LocalizationService } from 'src/app/services/localization.service';
import { environment } from './../../../environments/environment';
import { customStyling } from 'src/app/custom-styles/login-register';
import {trigger, style, animate, transition} from '@angular/animations';

import { AuthService } from '../../services/auth.service';

import '@everymatrix/general-player-forgot-password-form';
import '@everymatrix/general-player-login-form';
import '@everymatrix/general-player-register-form';
import '@everymatrix/user-login/dist/esm/user-login';
import '@everymatrix/general-registration/dist/esm/general-registration';

import { ActivatedRoute } from '@angular/router';

const enterTransition = transition(':enter', [style({opacity: 0}), animate(350, style({opacity:1}))
]);
const exitTransition = transition(':leave', [style({opacity: 1}), animate(350, style({opacity:0}))
]);
const fadeIn = trigger('fadeIn', [enterTransition]);
const fadeOut = trigger('fadeOut', [exitTransition]);

@Component({
  selector: 'app-login-register-controller',
  templateUrl: './login-register-controller.component.html',
  styleUrls: ['./login-register-controller.component.scss'],
  animations: [fadeIn, fadeOut]
})

export class LoginRegisterControllerComponent implements OnInit, OnDestroy {
  LoginRegisterConfig: LoginRegisterConfig = {} as LoginRegisterConfig;

  show: boolean = false;
  duration: number = 350;
  mobileView: boolean = false;
  userAgent = window.navigator.userAgent;
  displayNone: boolean = false;
  sectionHeaderBackgroundSrc: string | null = null;
  loginactive: boolean = true;
  cmsLoginData: any;
  cmsRegisterData: any;
  forgotPasswordActive: boolean = false;
  forgotpasswordkey: string = '';
  userRoles: string = '';
  btag:string = '';

  isOnNative: boolean = false;
  isLoading: boolean = false;

  constructor(
    private localizationService: LocalizationService,
    private cmsService: CmsService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.setConfig(environment);

    // Add event listener for message handling
    window.addEventListener('message', this.messageHandler.bind(this), false);

    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.LoginRegisterConfig.lang = selectedLang;
    });

    this.authService.getListOfRoles().subscribe((data) => {
      this.userRoles = data;
    });

    this.getCmsData();
  }

  getCmsData(): void {
    this.isLoading = true;

    this.cmsService.getCmsData(this.LoginRegisterConfig.cmsEndpoint, this.LoginRegisterConfig.lang, this.LoginRegisterConfig.env)
      .subscribe(
        (data: any) => {
          this.cmsLoginData = data.loginData;
          this.cmsRegisterData = data.registerData;
          
          if (this.loginactive) {
            this.sectionHeaderBackgroundSrc = this.cmsLoginData ? this.cmsLoginData.mobileImg : '';
          } else {
            this.sectionHeaderBackgroundSrc = this.cmsRegisterData ? this.cmsRegisterData.mobileImg : '';
          }
        },
        (error: any) => {
          // Handle errors if needed
          console.error(error);
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  setConfig(config: any): void {

    const { app, login } = mergeConfigs(config, window.navigator.userAgent);

    this.LoginRegisterConfig = {
      endpoint: app.endpoint,
      env: app.cmsEnv,
      lang: app.lang,
      cmsEndpoint: app.cmsEndpoint,
      captchakey: login.gmCore.captchakey,
      licenseyears: app.licenseyears,
      registerDisabledFields: app.registerDisabledFields,
      defaultOptions: app.registerDefaultOptions,
      savecredentials: login.gmCore.savecredentials,
      smsverification: login.gmCore.smsverification,
      simplepasswordvalidation: login.gmCore.simplepasswordvalidation,
      customInputTextSwitch: app.customInputTextSwitch,
      passwordReset: login.gm16.passwordReset,
      userEmailRegex: login.gm16.userEmailRegex,
      userEmailRegexOptions: login.gm16.userEmailRegexOptions,
      passwordRegex: login.gm16.passwordRegex,
      passwordRegexOptions: login.gm16.passwordRegexOptions,
      legacyLogin: app.featureFlag.legacyLogin,
      clientStyling: customStyling,
      dateformat: app.dateformat,
      buttoninsideform: app.buttoninsideform,
      btag: app.btag,
      legacyRegister: app.featureFlag.legacyRegister
    };
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.btag = params['btag'] || '';
    });

    this.isOnNative = !!isNative(this.userAgent);

    // Check if the component should be displayed initially based on 'show' property
    this.show = this.show === true;

    // Determine if the view is on a mobile device
    this.mobileView = isMobile(this.userAgent);
  }

  ngOnDestroy() {
    // Remove event listener when the component is destroyed
    window.removeEventListener('message', this.messageHandler);
  }

  close(): void {
    this.show = false;

    window.postMessage({ type: 'ModalClosed' }, window.location.href);
  }

  register(): void {
    this.sectionHeaderBackgroundSrc = this.cmsRegisterData ? this.cmsRegisterData.mobileImg : '';
    this.loginactive = false;
    this.forgotPasswordActive = false;
  }

  login() {
    this.sectionHeaderBackgroundSrc = this.cmsLoginData ? this.cmsLoginData.mobileImg : '';
    this.loginactive = true;
  }

  messageHandler(e: any): void {
    if (e.data.type === 'OpenLoginRegisterModal') {
      window.postMessage({ type: 'DisableScroll' }, window.location.href);
      this.show = true;


      switch (e.data.transition) {
        case 'ForgotPassword':
          this.login();
          this.forgotPasswordActive = true;
          this.forgotpasswordkey = e.data.key;
          break;

        case 'Register':
          this.register();
          break;

        case 'Login':
          this.forgotPasswordActive = false;
          this.login();
          break;

        default:
          // do nothing
          break;
      }
    }

    switch (e.data.type) {
      case 'NavForgotPassword':
        this.forgotPasswordActive = !this.forgotPasswordActive;
        break;

      case 'ToRegister':
        this.register();
        break;

      case 'ToLogin':
        this.forgotPasswordActive = false;
        this.login();
        break;

      case 'UserSessionID':
        window.postMessage({ type: 'EnableScroll' }, window.location.href);
        this.show = false;
        break;

      default:
        // do nothing
        break;
    }
  }
}
