<player-account-controller
  [attr.playerid]='userID'
  [attr.session]='sessionID'
  [attr.userroles]='userRoles'
  [attr.endpoint]='playerProfileConfig.endpoint'
  [attr.env]='playerProfileConfig.env'
  [attr.cmsendpoint]='playerProfileConfig.cmsEndpoint'
  [attr.customnotifications]='playerProfileConfig.customNotification'
  [attr.lang]='playerProfileConfig.lang'
  [attr.hasdefaultamount]='playerProfileConfig.hasdefaultamount'
  [attr.simplepasswordvalidation]="playerProfileConfig.simplepasswordvalidation"
  [attr.operator]="playerProfileConfig.operator"
  [attr.supportemail]="playerProfileConfig.supportEmail"
  [attr.tablabels]="playerProfileConfig.tablabels"
  [attr.historyenabled]="playerProfileConfig.historyenabled"
  [attr.deletelimit]="playerProfileConfig.deletelimit"
  [attr.limitsamount]="playerProfileConfig.limitsamount"
  [attr.limitsperiods]="playerProfileConfig.limitsperiods"
  [attr.numberofentries]="playerProfileConfig.numberofentries"
  [attr.customcurrencysymbols]="playerProfileConfig.customcurrencysymbols"
  [attr.currency]="playerProfileConfig.currency"
  [attr.apiversion]="playerProfileConfig.apiversion"
  [attr.clientstyling]="playerProfileConfig.clientstyling"
  [attr.custominputtextswitch]="playerProfileConfig.customInputTextSwitch"
  [attr.productType]="playerProfileConfig.productType"
  [attr.successUrl]="playerProfileConfig.successUrl"
  [attr.cancelUrl]="playerProfileConfig.cancelUrl"
  [attr.failUrl]="playerProfileConfig.failUrl"
  [attr.sportsUrl]="playerProfileConfig.sportsUrl"
  [attr.casinoUrl]="playerProfileConfig.casinoUrl"
  [attr.contactUrl]="playerProfileConfig.contactUrl"
  [attr.homeUrl]="playerProfileConfig.homeUrl"
  [attr.channel]="playerProfileConfig.channel"
  [attr.gmversion]="playerProfileConfig.gmversion"
  [attr.kyctitle]="playerProfileConfig.kycTitle"
  [attr.kycdescription]="playerProfileConfig.kycDescription"
></player-account-controller>
