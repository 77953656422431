<casino-promotions
  [attr.endpoint]="cmsEndpoint"
  [attr.lang]="lang"
  [attr.env]="env"
  [attr.containermaxwidth]="containerMaxWidth"
  [attr.userroles]="userRoles"
  [attr.modalpromotion]="modalpromotion"
  [attr.clientstyling]="clientstyling"
>
</casino-promotions>
