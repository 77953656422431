import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss']
})
export class ThemeToggleComponent implements OnInit {

  storageKey: string = 'theme-preference';
  theme: string = null;

  constructor(private renderer: Renderer2) { }

  toggleTheme() {
    this.theme = this.theme === 'light'
    ? 'dark'
    : 'light';

    this.setPreference();
  }

  setPreference() {
    localStorage.setItem(this.storageKey, this.theme);
    this.reflectPreference();
  }

  getPreference() {
    if (localStorage.getItem(this.storageKey))
      return localStorage.getItem(this.storageKey);
    else
      return window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
  }
  

  reflectPreference() {
    this.renderer.setAttribute(document.documentElement, 'data-theme', this.theme);
    this.renderer.setAttribute(document.querySelector('.ThemeToggle'), 'data-theme', this.theme);
    this.renderer.setAttribute(document.querySelector('#ThemeToggle'), 'aria-label', this.theme);
  }
  

  ngOnInit(): void {
    this.theme = this.getPreference();
    this.reflectPreference();
  }
}
