import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../../services/localization.service';

import { customStyling } from '../../custom-styles/500';

import '@everymatrix/casino-500';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent implements OnInit {
  lang:string = 'en';
  clientStyling:string = customStyling;

  constructor(
    private route: ActivatedRoute,
    private localizationService: LocalizationService
  ) {
    this.localizationService.currentLang().subscribe((selectedLang: string):void => {
      this.lang = selectedLang;
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params:any) => {
      this.localizationService.setLang(params.get('languageCode'));
    });
  }
}
