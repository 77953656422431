import  * as _ from 'lodash';

import { environmentMobile } from '../environments/environment.mobile';
import { environmentNative } from '../environments/environment.native';

export const isMobile = (userAgent:string):boolean => {
  return !!(
    userAgent.toLowerCase().match(/android/i) ||
    userAgent.toLowerCase().match(/blackberry|bb/i) ||
    userAgent.toLowerCase().match(/iphone|ipad|ipod/i) ||
    userAgent.toLowerCase().match(/windows phone|windows mobile|iemobile|wpdesktop/i)
  );
};

export const isNative = (userAgent:string):RegExpMatchArray => {
  return (/(CustomNative:)(\\?|[\w.].+)/ig).exec(userAgent.toLowerCase());
};

export const mergeConfigs = (config:any, userAgent:string):any => {
  if (isMobile(userAgent)) {
    _.merge(config, config, environmentMobile);
  }

  if (isNative(userAgent)) {
    _.merge(config, config, environmentNative);
  }

  return config;
};
