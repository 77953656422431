import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  private langSubject = new BehaviorSubject<string>(this.getLang());
  private defaultLang = environment.app.language;

  constructor(private router:Router, private route:ActivatedRoute) {
    if (this.getLang() == null) {
      localStorage.setItem('selectedLang', this.defaultLang);
    }
  }

  currentLang(): Observable<string> {
    return this.langSubject.asObservable();
  }

  getLang(): string {
    const lang = localStorage.getItem('selectedLang');

    if (!lang) {
      return this.defaultLang || 'en';
    }

    return lang;
  }

  setLang(lang:string, navigate:boolean = true): void {
    localStorage.setItem('selectedLang', lang.toLowerCase());

    let urlSplit = this.router.url.split('/').map((item) => {
      if (item.includes('?')) {
        return item.split('?')[0];
      }

      return item;
    });

    urlSplit = urlSplit.splice(2, urlSplit.length).map((item) => {
      return decodeURIComponent(item);
    });

    if (navigate) {
      this.router.navigate([`/${lang.toLowerCase()}/${urlSplit.join('/')}`]);
    }

    this.langSubject.next(lang.toLowerCase());
  }
}
