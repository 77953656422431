import { Component, OnInit } from '@angular/core';
import { CategoriesPageConfig } from './categories.type';

import { LocalizationService } from '../../services/localization.service';
import { AuthService } from '../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';

import '@everymatrix/casino-navigation-search';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

import { customStyling } from '../../custom-styles/categories';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categoriesPageConfig:CategoriesPageConfig = {} as CategoriesPageConfig;

  sessionID:string;
  userID:string;

  constructor(
    private router: Router,
    private localizationService: LocalizationService,
    private authService: AuthService
  ) {
    this.authService.isLoggedIn().subscribe((loggedIn:boolean) => {
      if (loggedIn == true) {
        const data:any = this.authService.getSession();

        this.sessionID = this.authService.getSessionID();
        this.userID = data.UserID;
      }
    });

    this.setConfig(environment);

    this.localizationService.currentLang().subscribe((selectedLang:string) => {
      this.categoriesPageConfig.lang = selectedLang;
    });


    router.events
    .subscribe((event) => {
      if ( event instanceof NavigationEnd ) {
        this.categoriesPageConfig.activecategory = this.router.url.split('/')[3];
      }
    });
  }

  setConfig(config:any): void {
    const { app, casinoLobby } = mergeConfigs(config, window.navigator.userAgent);

    this.categoriesPageConfig = {
      endpoint : app.endpoint,
      userid: this.userID,
      session: this.sessionID,
      datasource: casinoLobby.datasources.default,
      endpointcategories: app.endpoint,
      activecategory: casinoLobby.activecategory,
      endpointproviders: app.endpoint,
      excludedflags: casinoLobby.filteredcategories,
      lang: app.language,
      style: customStyling,
      integratedgameframedesktop: casinoLobby.featureFlag.integratedgameframedesktop,
      integratedgameframemobile: casinoLobby.featureFlag.integratedgameframemobile,
      showfavorites: casinoLobby.favorites
    };
  }

  ngOnInit(): void {

  }
}
