import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { LiveLobbyConfig } from './live-lobby.type';

import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';
import { CmsService } from 'src/app/services/cms.service';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

import { customStyling } from '../../custom-styles/live-lobby';

import '@everymatrix/casino-lobby';

@Component({
  selector: 'app-live-lobby',
  templateUrl: './live-lobby.component.html',
  styleUrls: ['./live-lobby.component.scss']
})
export class LiveLobbyComponent implements OnInit {
  private sessionData:any;

  isLoggedIn:boolean = false;
  sessionID:string = '';
  userID:string = '';

  liveLobbyConfig:LiveLobbyConfig = {} as LiveLobbyConfig;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private localizationService: LocalizationService,
    private cmsService: CmsService
  ) {
    this.setConfig(environment);

    window.addEventListener('message', this.messageHandler, false);

    this.localizationService.currentLang().subscribe((selectedLang:string) => {
      this.liveLobbyConfig.lang = selectedLang;

      if (this.liveLobbyConfig.fetchCMSDatasource) {
        this.getCMSDataSource(this.liveLobbyConfig.datasources.default);
      } else {
        if (this.liveLobbyConfig.datasources[`${selectedLang}`]) {
          this.liveLobbyConfig.datasource = this.liveLobbyConfig.datasources[`${selectedLang}`] || this.liveLobbyConfig.datasources['default'];
        }
      }
    });

    this.authService.isLoggedIn().subscribe((loggedIn: boolean):void => {
      this.isLoggedIn = false;

      if (loggedIn) {
        this.sessionData = this.authService.getSession();

        this.sessionID = this.authService.getSessionID();
        this.userID = this.sessionData.UserID;

        this.isLoggedIn = true;
      } else {
        this.sessionID = '';
        this.userID = '';

        this.isLoggedIn = false;
      }
    });

    this.liveLobbyConfig.activecategory = this.router.url.split('/')[3] || 'LOBBY';
  }

  messageHandler = (e: any): void => {
    if (e.data && e.data.type == this.liveLobbyConfig.actionevent) {
      this.router.navigate([e.data.itemId], { relativeTo: this.route });
    }

    if (e.data && e.data.type == 'UpdateSelectedVendorFilters') {
      /*this.router.navigate([], {
        queryParams: {
          gameID: null,
        },
        queryParamsHandling: 'merge',
      });*/
    }
  };

  getCMSDataSource(baseDS:any): void {
    this.cmsService.getDatasource('live-casino').then((datasource:string | any):void => {
      this.liveLobbyConfig.datasource = datasource;
    }).catch(() => {
      this.liveLobbyConfig.datasource = baseDS; // if the call fails, use the default DS
    });
  }

  setConfig(config:any): void {
    const { app, liveCasinoLobby } = mergeConfigs(config, window.navigator.userAgent);

    this.liveLobbyConfig = {
      endpoint: app.endpoint,
      lang: app.language,
      identity: app.identity,
      fetchCMSDatasource: app.fetchCMSDatasource,
      datasources: liveCasinoLobby.datasources,
      winnersdatasources: liveCasinoLobby.winnersdatasources,
      mostPlayed: liveCasinoLobby.featureFlag.mostPlayed,
      mostplayedrounds: liveCasinoLobby.mostplayedrounds,
      lastplayed: liveCasinoLobby.featureFlag.lastplayed,
      lastplayedperiod: liveCasinoLobby.lastplayedperiod,
      mostplayedperiod: liveCasinoLobby.mostplayedperiod,
      favorites: liveCasinoLobby.featureFlag.favorites,
      loginEvent: liveCasinoLobby.loginEvent,
      registerEvent: liveCasinoLobby.registerEvent,
      depositEvent: liveCasinoLobby.depositEvent,
      lobbyID: liveCasinoLobby.lobbyID,
      containermaxwidth: liveCasinoLobby.containermaxwidth,
      haspanicbutton: liveCasinoLobby.featureFlag.haspanicbutton,
      clockformat: liveCasinoLobby.clockformat,
      currency: liveCasinoLobby.currency,
      livecasino: liveCasinoLobby.featureFlag.livecasino,
      activecategory: liveCasinoLobby.activecategory,
      actionevent: liveCasinoLobby.actionevent,
      gamemodal: liveCasinoLobby.featureFlag.gamemodal,
      randomgame: liveCasinoLobby.featureFlag.randomgame,
      integratedgameframedesktop: liveCasinoLobby.featureFlag.integratedgameframedesktop,
      integratedgameframemobile: liveCasinoLobby.featureFlag.integratedgameframemobile,
      gamepagemodalurl: liveCasinoLobby.featureFlag.gamepagemodalurl,
      enableautoscroll: liveCasinoLobby.enableautoscroll,
      tabsorder: liveCasinoLobby.tabsorder,
      isrecentavailable: liveCasinoLobby.isrecentavailable,
      istopavailable: liveCasinoLobby.istopavailable,
      enablecasinowinners: liveCasinoLobby.enablecasinowinners,
      enablejackpotbanner: liveCasinoLobby.enablejackpotbanner,
      clientstyling: customStyling,
      addfilterselector: liveCasinoLobby.featureFlag.addfilterselector,
      addsortingselector: liveCasinoLobby.featureFlag.addsortingselector,
      filterbylogo: liveCasinoLobby.featureFlag.filterbylogo,
      visiblegames: liveCasinoLobby.visiblegames,
      casinogamesgridslider: liveCasinoLobby.casinogamesgridslider
    };

    // if the ff is on, make the call for the DS, else use the default from the env
    if (this.liveLobbyConfig.fetchCMSDatasource) {
      this.getCMSDataSource(liveCasinoLobby.datasources.default);
    } else {
      this.liveLobbyConfig.datasource = liveCasinoLobby.datasources.default;
    }
  }

  ngOnInit(): void {
    //Banner matrix fix when changing page from profile section
    window.postMessage({
      type: 'BMFE:sliderRefresh',
      payload: {}
    }, '*');

    this.route.paramMap.subscribe((params: any): void => {
      this.localizationService.setLang(params.get('languageCode'), false);
    });

    this.router.events
      .subscribe((event: any): void => {
        if ( event instanceof NavigationEnd ) {
          this.liveLobbyConfig.activecategory = this.router.url.split('/')[3] || 'LOBBY';
        }
      });
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.messageHandler);
  }
}
