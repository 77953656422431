import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { LocalizationService } from './localization.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class RouteResolverService implements CanActivate {
  private config:any = {};
  language:string = 'en';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localizationService: LocalizationService
  ) {
    this.setConfig(environment);

    this.localizationService.currentLang().subscribe((selectedLang:string) => {
      this.language = selectedLang;
    });
  }

  setConfig(config: any): void {
    const { app: { disabledRoutes } } = config;

    this.config = disabledRoutes;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const activeRoute: string = state.url;
    const findRoute = this.config.find((url: string) => activeRoute.includes(url));

    if (findRoute) {
      this.router.navigate([`/${this.language}/404-not-found`]);
      return false;
    }

    return true;
  }
}
