import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Route, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LocalizationService } from './localization.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuardService implements CanLoad, CanActivate {
  language:string = 'en';

  constructor(
    private authService: AuthService,
    private router: Router,
    private localizationService: LocalizationService
  ) {
    this.localizationService.currentLang().subscribe((selectedLang:string) => {
      this.language = selectedLang;
    });
  }

  canLoad(route: Route) {
    this.authService.checkSession();

    return this.authService.isLoggedIn().pipe(map((isLoggedIn) => {
      if (isLoggedIn) {
        return true;
      } else {
        this.router.navigate([`/${this.language}/403-not-authorized`]);

        return false;
      }
    }));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.authService.checkSession();

    return this.authService.isLoggedIn().pipe(map((isLoggedIn) => {
      if (isLoggedIn) {
        return true;
      } else {
        this.router.navigate([`/${this.language}/403-not-authorized`]);

        return false;
      }
    }));
  }
}
