import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { LocalizationService } from '../../services/localization.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';

import '@everymatrix/casino-promotions';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

import { customStyling } from 'src/app/custom-styles/promotions';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit, OnDestroy {

  cmsEndpoint:string | undefined = '';
  lang:string | undefined = '';
  env:string | undefined = '';
  containerMaxWidth:number | undefined;
  userRoles:string | undefined = '';
  modalpromotion:boolean | undefined;
  clientstyling:string = customStyling;

  private routerSubscription: Subscription | undefined;
  private routeParamsSubscription: Subscription | undefined;

  constructor(
    private localizationService: LocalizationService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {
    this.setConfig(environment);

    this.authService.getListOfRoles().subscribe((data) => {
      this.userRoles = data;
    });

    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.lang = selectedLang;
    });

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.route.firstChild?.params) {
          this.routeParamsSubscription = this.route.firstChild.params.subscribe(params => {
            window.postMessage({ type: 'promotionPath', id: params['promotionId'] });
          });
        } else {
          window.postMessage({ type: 'promotionPath', id: null});
        }
      }
    });
  }

  setConfig(config:any): void {
    const { app } = mergeConfigs(config, window.navigator.userAgent);

    this.cmsEndpoint = app.cmsEndpoint;
    this.env = app.cmsEnv;
    this.containerMaxWidth = app.containerMaxWidth;
    this.modalpromotion = app.featureFlag.modalpromotion;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'), false);
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from the router and routeParams subscriptions when the component is destroyed.
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }
  }
}
