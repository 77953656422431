export const customStyling: string = `
  .SearchResultsContainer {
    min-height: 40vh;
  }

  .SearchInput {
    background-color: rgba(156, 163, 175, 0.25); 
    border-color: transparent; 
    color: #e0e7ff;
  }

  .SearchInput::placeholder {
    color: var(--emfe-w-color-white, #FFFFFF);
  }

  .SearchCancelButton {
    color: var(--emfe-w-color-white, #FFFFFF);
  }

  .SearchIcon svg path, circle {
    stroke: #FFFFFF;
  }

  .SearchClearButton svg path {
    stroke: #FFFFFF;
  }

  .SearchMessage {
    color: var(--emfe-w-color-white, #FFFFFF);
  }
  
  .ButtonsContainer .LoginButton {
    color: #f57e32;
    border-color: #f57e32;
  }
  .RandomGameLoading,
  .RandomGameDetails .RandomGameVendor, 
  .RandomGameDetails .RandomGameTitle { color: var(--emfe-w-color-black); }
`;
