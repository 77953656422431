<app-login-register-controller [attr.show]="config.show"></app-login-register-controller>

<div *ngIf="showPlayerActions" class="UserActionModal">
  <div class="Container">
    <user-action-controller
      [attr.include-submit-button]="config.userConsents.includeSubmitButton"
      [attr.submit-button-text]="config.userConsents.submitButtonText"
      [attr.user-notice-text]="config.userConsents.userNoticeText"
      [attr.active-user-actions]="config.playerActions"
      [attr.endpoint]="config.endpoint.substring(0, config.endpoint.length - 2)"
      [attr.user-session]="sessionID"
      [attr.user-id]="userID"
      [attr.use-postmessage]="config.usePostmessage"
      [attr.show-close-button]="config.userConsents.showCloseButton"
    ></user-action-controller>
  </div>
</div>

<div *ngIf="showTutorialWidget">
  <div class="TutorialSliderModalWindow">
    <div class="CloseButton" (click)="closeTutorialWidget()">
      <svg width="33.33" height="33.33" xmlns="http://www.w3.org/2000/svg">
        <line x1="3.33" y1="3.33" x2="29.99" y2="29.99" stroke="white" stroke-width="3.33"/>
        <line x1="29.99" y1="3.33" x2="3.33" y2="29.99" stroke="white" stroke-width="3.33"/>
      </svg>          
    </div>
    <general-tutorial-slider
      class="TutorialSliderContainer"
      [attr.client-styling]="config.style"
      [attr.language]="config.language"
      [attr.cms-endpoint]="config.cmsEndpoint"
      [attr.user-roles]="userRoles"
      [attr.cms-env]="config.env"
      [attr.show-slider-dots]="config.tutorial.showSliderDots"
      [attr.show-slider-arrows]="config.tutorial.showSliderArrows"
      [attr.show-slider-arrows-mobile]="config.tutorial.showSliderArrowsMobile"
      [attr.enable-auto-scroll]="config.tutorial.enableAutoScroll"
      [attr.interval-period]="config.tutorial.intervalPeriod"
      [attr.scroll-items]="config.tutorial.scrollItems"
      [attr.items-per-page]="config.tutorial.itemsPerPage"
    ></general-tutorial-slider>
  </div>
</div>

<div *ngIf="isLoggedIn && config.enableUserActions">
  <div class="ActionModalWindow">
    <user-action
      [attr.userid]="userID"
      [attr.endpoint]="config.endpoint"
    ></user-action>
  </div>
</div>

<div *ngIf="!config.displayGamePageOnly">
  <div *ngIf="isLoggedIn">
    <casino-header-controller
      [attr.session]="sessionID"
      [attr.userid]="userID"
      [attr.userroles]="userRoles"
      [attr.cmsendpoint]="config.cmsEndpoint"
      [attr.env]="config.env"
      [attr.lang]="config.language"
      [attr.languageslist]="config.languageslist"
      [attr.identity]="config.identity"
      [attr.endpoint]="config.endpoint"
      [attr.operatorid]="config.operatorid"
      [attr.actionevent]="config.actionevent"
      [attr.activecategory]="config.activeRoute"
      [attr.clientstyling]="config.style"
      [attr.customlocaleidentifier]="config.customLocaleIdentifier"
      [attr.displaybalanceoption]="config.displaybalanceoption"
      [attr.currencyseparator]="config.displayBalance.separator"
      [attr.currencydecimal]="config.displayBalance.decimal"
      [attr.currencyprecision]="config.displayBalance.precision"
      [attr.countryflagheader]="config.countryflagheader"
      [attr.gmversion]="config.gmversion"
      [attr.countryflaghamburger]="config.countryflaghamburger"
    ></casino-header-controller>
  </div>
  <div *ngIf="!isLoggedIn">
    <casino-header-controller
      [attr.cmsendpoint]="config.cmsEndpoint"
      [attr.env]="config.env"
      [attr.lang]="config.language"
      [attr.languageslist]="config.languageslist"
      [attr.identity]="config.identity"
      [attr.endpoint]="config.endpoint"
      [attr.operatorid]="config.operatorid"
      [attr.actionevent]="config.actionevent"
      [attr.activecategory]="config.activeRoute"
      [attr.userroles]="userRoles"
      [attr.clientstyling]="config.style"
      [attr.customlocaleidentifier]="config.customLocaleIdentifier"
      [attr.countryflagheader]="config.countryflagheader"
      [attr.countryflaghamburger]="config.countryflaghamburger"
    ></casino-header-controller>
  </div>
</div>

<div class="PageWrapperContent"
  [ngClass]="{'PageWrapperContentSpacing' : !config.displayGamePageOnly && !isMobileDevice, 'PageWrapperContentSpacingMobile' : !config.displayGamePageOnly && isMobileDevice}">
  <div *ngIf="config.bannersEnabled">
    <div [style.display]="showBanners ? '' : 'none'">
      <div class="bannerMatrix">
        <bannermatrix-banner
          height="300px"
          [attr.operator-id]="config.bannermatrix.operatorID"
          [attr.page-tag]="config.bannermatrix.pagetag"
          [attr.position-tag]="config.bannermatrix.positiontag"
          [attr.webapi-realm]="config.bannermatrix.realm"
          [attr.webapi-url]="config.bannermatrix.webapi"
          [attr.language]="config.language"
          [attr.logged-in]="isLoggedIn"
          [attr.active-betting-offers]="config.bannermatrix.bettingoffers"
          [attr.demo]="config.bannermatrix.demo"
        ></bannermatrix-banner>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>

  <div *ngIf="!config.displayGamePageOnly && displayFooter">
    <div *ngIf="config.footer.footerversion == 1">
      <casino-footer
        [attr.baseurl]="config.origin"
        [attr.endpoint]="config.cmsEndpoint"
        [attr.userendpoint]="config.endpoint"
        [attr.lang]="config.language"
        [attr.env]="config.env"
        [attr.session]="sessionID"
        [attr.userid]="userID"
        [attr.userroles]="userRoles"
        [attr.clockenabled]="config.footer.clockenabled"
        [attr.sessiontimerenabled]="config.footer.sessiontimerenabled"
        [attr.languageselectorenabled]="config.footer.languageselectorenabled"
        [attr.panicbuttonenabled]="config.footer.panicbuttonenabled"
      ></casino-footer>
    </div>
    <div *ngIf="config.footer.footerversion == 2">
      <casino-footer-v2
        [attr.baseurl]="config.origin"
        [attr.endpoint]="config.cmsEndpoint"
        [attr.userendpoint]="config.endpoint"
        [attr.lang]="config.language"
        [attr.env]="config.env"
        [attr.session]="sessionID"
        [attr.userid]="userID"
        [attr.clockenabled]="config.footer.clockenabled"
        [attr.clocksecondsenabled]="config.footer.clocksecondsenabled"
        [attr.clockcustomformat]="config.footer.clockcustomformat"
        [attr.clocktext]="config.footer.clocktext"
        [attr.languageslist]="config.languageslist"
        [attr.languagedisplaytype]="config.footer.languagedisplaytype"
        [attr.sessiontimerenabled]="config.footer.sessiontimerenabled"
        [attr.languageselectorenabled]="config.footer.languageselectorenabled"
        [attr.panicbuttonenabled]="config.footer.panicbuttonenabled"
        [attr.target]="config.footer.target"
        [attr.clientstyling]="config.style"
        ></casino-footer-v2>
    </div>
  </div>

  <div *ngIf="config.mobileBottomNavigationEnabled && !config.displayGamePageOnly && isMobileDevice">
    <general-navigation-bar
      [attr.cmsendpoint]="config.cmsEndpoint"
      [attr.lang]="config.language"
      [attr.env]="config.env"
      [attr.userroles]="userRoles"
      [attr.clientstyling]="config.style"
      [attr.activecategory]="config.activeRoute"
    ></general-navigation-bar>
  </div>

  <app-theme-toggle *ngIf="config.themeToggleEnabled"></app-theme-toggle>
</div>
