
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { CasinoLobbyConfig } from './casino-lobby.type';

import {AuthService} from '../../services/auth.service';
import {LocalizationService} from '../../services/localization.service';
import { CmsService } from 'src/app/services/cms.service';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

import { customStyling } from '../../custom-styles/casino-lobby';

import '@everymatrix/casino-lobby';

@Component({
  selector: 'app-casino-lobby',
  templateUrl: './casino-lobby.component.html',
  styleUrls: ['./casino-lobby.component.scss']
})
export class CasinoLobbyComponent implements OnInit {
  multilingualContent: { [key: string]: any };
  private sessionData: any;

  isLoggedIn: boolean = false;
  sessionID: string = '';
  userID: string = '';
  userRoles:string = '';

  casinoLobbyConfig: CasinoLobbyConfig = {} as CasinoLobbyConfig;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private localizationService: LocalizationService,
    private cmsService: CmsService
  ) {
    this.setConfig(environment);

    window.addEventListener('message', this.messageHandler, false);

    this.authService.getListOfRoles().subscribe((data) => {
      this.userRoles = data;
    });

    this.localizationService.currentLang().subscribe((selectedLang:string) => {
      this.casinoLobbyConfig.lang = selectedLang;

      if (this.casinoLobbyConfig.fetchCMSDatasource) {
        this.getCMSDataSource(this.casinoLobbyConfig.datasources.default);
      } else {
        if (this.casinoLobbyConfig.datasources[`${selectedLang}`]) {
          this.casinoLobbyConfig.datasource = this.casinoLobbyConfig.datasources[`${selectedLang}`] || this.casinoLobbyConfig.datasources['default'];
        }
      }
    });

    this.authService.isLoggedIn().subscribe((loggedIn: boolean):void => {
      this.isLoggedIn = false;

      if (loggedIn) {
        this.sessionData = this.authService.getSession();

        this.sessionID = this.authService.getSessionID();
        this.userID = this.sessionData.UserID;

        this.isLoggedIn = true;
      } else {
        this.sessionID = '';
        this.userID = '';

        this.isLoggedIn = false;
      }
    });

    this.casinoLobbyConfig.activecategory = this.router.url.split('/')[3] || 'LOBBY';
  }

  messageHandler = (e:any): void => {
    if (e.data && e.data.type == this.casinoLobbyConfig.actionevent) {
      this.router.navigate([e.data.itemId], { relativeTo: this.route });
    }

    if (e.data && e.data.type == 'UpdateSelectedVendorFilters') {
      /*this.router.navigate([], {
        queryParams: {
          gameID: null,
        },
        queryParamsHandling: 'merge',
      });*/
    }
  };

  getCMSDataSource(baseDS:any): void {
    this.cmsService.getDatasource('casino').then((datasource:string | any):void => {
      this.casinoLobbyConfig.datasource = datasource;
    }).catch(() => {
      this.casinoLobbyConfig.datasource = baseDS; // if the call fails, use the default DS
    });
  }

  setConfig(config:any): void {
    const { app, casinoLobby: { winnersWidget, jackpotBannersWidget, collectionsProvidersWidget },  casinoLobby } = mergeConfigs(config, window.navigator.userAgent);

    this.casinoLobbyConfig = {
      endpoint: app.endpoint,
      lang: app.language,
      identity: app.identity,
      fetchCMSDatasource: app.fetchCMSDatasource,
      datasources: casinoLobby.datasources,
      currency: casinoLobby.currency,
      mostPlayed: casinoLobby.featureFlag.mostPlayed,
      mostplayedrounds: casinoLobby.mostplayedrounds,
      lastplayed: casinoLobby.featureFlag.lastplayed,
      lastplayedperiod: casinoLobby.lastplayedperiod,
      mostplayedperiod: casinoLobby.mostplayedperiod,
      favorites: casinoLobby.featureFlag.favorites,
      winnersdatasources: casinoLobby.winnersdatasources,
      loginEvent: casinoLobby.loginEvent,
      registerEvent: casinoLobby.registerEvent,
      depositEvent: casinoLobby.depositEvent,
      lobbyID: casinoLobby.lobbyID,
      containermaxwidth: casinoLobby.containermaxwidth,
      haspanicbutton: casinoLobby.featureFlag.haspanicbutton,
      clockformat: casinoLobby.clockformat,
      activecategory: casinoLobby.activecategory,
      clientstyling: customStyling,
      actionevent: casinoLobby.actionevent,
      gamemodal: casinoLobby.featureFlag.gamemodal,
      randomgame: casinoLobby.featureFlag.randomgame,
      casinogamesgridslider: casinoLobby.casinogamesgridslider,
      randombuttonicon: casinoLobby.randombuttonicon,
      playforfun: casinoLobby.featureFlag.playforfun,
      visiblegames: casinoLobby.visiblegames,
      collectionprovidersgamenames: casinoLobby.collectionprovidersgamenames,
      integratedgameframedesktop: casinoLobby.featureFlag.integratedgameframedesktop,
      integratedgameframemobile: casinoLobby.featureFlag.integratedgameframemobile,
      gamepagemodalurl: casinoLobby.featureFlag.gamepagemodalurl,
      showsubgroups: casinoLobby.featureFlag.showsubgroups,
      addfilterselector: casinoLobby.featureFlag.addfilterselector,
      addsortingselector: casinoLobby.featureFlag.addsortingselector,
      filterbylogo: casinoLobby.featureFlag.filterbylogo,
      filteredcategories: casinoLobby.filteredcategories,
      filteredrandomgamecategories: casinoLobby.filteredrandomgamecategories,
      positioncasinocalendar: casinoLobby.positioncasinocalendar,
      winnersWidget: {
        positionwinnerswidget: winnersWidget?.positionwinnerswidget,
        periodrecent: winnersWidget?.periodrecent,
        periodtop: winnersWidget?.periodtop,
        numberusersrecent: winnersWidget?.numberusersrecent,
        numberuserstop: winnersWidget?.numberuserstop,
        amountlimit: winnersWidget?.amountlimit,
        vendorcategory: winnersWidget?.vendorcategory,
        isrecentavailable: winnersWidget?.isrecentavailable,
        istopavailable: winnersWidget?.istopavailable,
        defaultcurrency: winnersWidget?.defaultcurrency,
        enableautoscroll: winnersWidget?.enableautoscroll,
        tabsorder: winnersWidget?.tabsorder
      },
      jackpotBannersWidget: {
        positionjackpotbanners: jackpotBannersWidget?.positionjackpotbanners,
        caticon0: jackpotBannersWidget?.caticon0,
        caticon1: jackpotBannersWidget?.caticon1,
        caticon2: jackpotBannersWidget?.caticon2,
        caticon3: jackpotBannersWidget?.caticon3,
        backgroundsrc: jackpotBannersWidget?.backgroundsrc,
        backgroundsrcmobile: jackpotBannersWidget?.backgroundsrcmobile,
        titlelogopath: jackpotBannersWidget?.titlelogopath,
        iconlabels: jackpotBannersWidget?.iconlabels,
        visualstructure: jackpotBannersWidget?.visualstructure
      },
      collectionsProvidersWidget: {
        positioncollections: collectionsProvidersWidget?.positioncollections,
        type: collectionsProvidersWidget?.type,
        titletype: collectionsProvidersWidget?.titletype
      }
    };

    // if the ff is on, make the call for the DS, else use the default from the env
    if (this.casinoLobbyConfig.fetchCMSDatasource) {
      this.getCMSDataSource(casinoLobby.datasources.default);
    } else {
      this.casinoLobbyConfig.datasource = casinoLobby.datasources.default;
    }
  }

  ngOnInit(): void {
    //Banner matrix fix when changing page from profile section
    window.postMessage({
      type: 'BMFE:sliderRefresh',
      payload: {}
    }, '*');

    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'), false);
    });

    this.router.events
      .subscribe((event) => {
        if ( event instanceof NavigationEnd ) {
          this.casinoLobbyConfig.activecategory = this.router.url.split('/')[3] || 'LOBBY';
        }
      });
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.messageHandler);
  }
}
