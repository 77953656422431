import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { ActivatedRoute } from '@angular/router';

import { customStyling } from '../../custom-styles/403';

import '@everymatrix/casino-403';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {
  lang: string = 'en';
  clientStyling: string = customStyling;

  constructor(
    private route:ActivatedRoute,
    private localizationService: LocalizationService
  ) {
    this.localizationService.currentLang().subscribe((selectedLang: string):void => {
      this.lang = selectedLang;
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'));
    });
  }
}
