import { Component, OnInit } from '@angular/core';

import {TournamentsMyLobbyConfig} from './tournaments-my-lobby.type';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';


@Component({
  selector: 'app-tournaments-my-lobby',
  templateUrl: './tournaments-my-lobby.component.html',
  styleUrls: ['./tournaments-my-lobby.component.scss']
})
export class TournamentsMyLobbyComponent implements OnInit {

  private sessionData:any;

  isLoggedIn:boolean = false;
  sessionID:string = '';
  userID:string = '';
  currency:string = '';
  filter:string = '';
  toursMyLobbyConfig:TournamentsMyLobbyConfig = {} as TournamentsMyLobbyConfig;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,private localizationService: LocalizationService) {
    
    this.setConfig(environment);

    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.toursMyLobbyConfig.lang = selectedLang;
    });

    this.authService.isLoggedIn().subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        this.sessionData = this.authService.getSession();

        this.sessionID = this.sessionData.Guid;
        this.userID = this.sessionData.UserID;
        this.currency = this.sessionData.Currency;

        this.isLoggedIn = true;
      } else {
        this.sessionID = '';
        this.userID = '';

        this.isLoggedIn = false;
      }
    });
    window.addEventListener('message', this.messageHandler.bind(this));
  }

  setConfig(config:any): void {
    const { app, tournamentsMyLobby } = mergeConfigs(config, window.navigator.userAgent);

    this.toursMyLobbyConfig = {
      endpoint: app.endpointCE,
      lang: app.language,
      filter: tournamentsMyLobby.filter,
      state: tournamentsMyLobby.state,
      states: tournamentsMyLobby.states
    };
  }

  messageHandler(e:any): void {
    if (e.data && e.data.type == 'TournamentsTabSwitch') {
      const tab = e.data.tab;
      this.toursMyLobbyConfig.state = tab;
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'));
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.messageHandler);
  }

}
