<casino-navigation-search
[attr.lang]='categoriesPageConfig.lang'
[attr.endpoint]='categoriesPageConfig.endpoint'
[attr.userid]='categoriesPageConfig.userid'
[attr.session]='categoriesPageConfig.session'
[attr.datasource]='categoriesPageConfig.datasource'
[attr.endpointcategories]='categoriesPageConfig.endpointcategories'
[attr.activecategory]='categoriesPageConfig.activecategory'
[attr.endpointproviders]='categoriesPageConfig.endpointproviders'
[attr.excludedflags]='categoriesPageConfig.excludedflags'
[attr.clientstyling]='categoriesPageConfig.style'
[attr.integratedgameframedesktop]='categoriesPageConfig.integratedgameframedesktop'
[attr.integratedgameframemobile]='categoriesPageConfig.integratedgameframemobile'
[attr.showfavorites]='categoriesPageConfig.showfavorites'
>
</casino-navigation-search>