interface ScriptDataSet {
  name: string;
  method: any;
  target: string;
}

export const ScriptStore: ScriptDataSet[] = [
  {
    name: '',
    method: '',
    target: ''
  }
];
