import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { TournamentPageConfig } from './tournament-page.type';

import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

import { customStyling } from '../../custom-styles/tournaments-page';

import '@everymatrix/casino-tournaments-page-controller';

@Component({
  selector: 'app-tournament-page',
  templateUrl: './tournament-page.component.html',
  styleUrls: ['./tournament-page.component.scss']
})
export class TournamentPageComponent implements OnInit {
  private sessionData:any;

  isLoggedIn:boolean = false;
  sessionID:string = '';
  userID:string = '';
  currency:string = '';

  pageConfig:TournamentPageConfig = {} as TournamentPageConfig;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private localizationService: LocalizationService
  ) {
    this.setConfig(environment);

    this.route.paramMap.subscribe((params:any) => {
      this.pageConfig.tourID = params.get('tourID');
    });

    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.pageConfig.lang = selectedLang;
    });

    this.authService.isLoggedIn().subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        this.sessionData = this.authService.getSession();

        this.sessionID = this.sessionData.Guid;
        this.userID = this.sessionData.UserID;
        this.currency = this.sessionData.Currency;

        this.isLoggedIn = true;
      } else {
        this.sessionID = '';
        this.userID = this.sessionData.UserID;

        this.isLoggedIn = false;
      }
    });
  }

  setConfig(config:any): void {
    const { app, tournamentsPage } = mergeConfigs(config, window.navigator.userAgent);

    this.pageConfig = {
      endpoint: app.endpointCE,
      lang: app.language,
      cmsEndpoint: tournamentsPage.cmsendpoint,
      showLeaderboard: tournamentsPage.featureFlag.showLeaderboard,
      tablelimit: tournamentsPage.tablelimit,
      tourID: '',
      keepBrowserContext: tournamentsPage.featureFlag.keepBrowserContext,
      viewmode: tournamentsPage.viewmode,
      style: customStyling
    };
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'), false);
    });
  }
}
