import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../../services/localization.service';

import { customStyling  } from '../../custom-styles/404';

import '@everymatrix/casino-404';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  lang:string = 'en';
  clientStyling:string = customStyling;

  constructor(
    private route: ActivatedRoute,
    private localizationService: LocalizationService
  ) {
    this.localizationService.currentLang().subscribe((selectedLang: string):void => {
      this.lang = selectedLang;
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'), false);
    });
  }

  toggleLogin() {
    window.postMessage({ type: 'GoToHomepage' }, window.location.href);
  }

}
