import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { GamePageConfig } from './game-page.type';

import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';

import { environment } from './../../../environments/environment';
import { mergeConfigs, isMobile } from '../../../utils/utils';

import { customStyling } from '../../custom-styles/game-page';

import '@everymatrix/casino-game-page';

@Component({
  selector: 'app-game-page',
  templateUrl: './game-page.component.html',
  styleUrls: ['./game-page.component.scss']
})
export class GamePageComponent implements OnInit {
  private sessionData: any;

  isLoggedIn: boolean = false;
  sessionID: string = '';
  userID: string = '';
  gameID: string = '';
  prevParams: string = '';

  gamePageConfig: GamePageConfig = {} as GamePageConfig;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private localizationService: LocalizationService
  ) {
    this.setConfig(environment);

    this.gameID = this.route.snapshot.paramMap.get('gameSlug');

    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.gamePageConfig.lang = selectedLang;

      if (this.gamePageConfig.datasources[`${selectedLang}`]) {
        this.gamePageConfig.datasource = this.gamePageConfig.datasources[`${selectedLang}`];
      }
    });

    this.authService.isLoggedIn().subscribe((loggedIn: boolean) => {
      this.isLoggedIn = false;

      if (loggedIn) {
        this.sessionData = this.authService.getSession();
        this.sessionID = this.authService.getSessionID();

        this.userID = this.sessionData.UserID;
        this.isLoggedIn = true;
      } else {
        this.sessionID = '';
        this.userID = '';

        this.isLoggedIn = false;
      }
    });
  }

  containsEncodedComponents(value:string):boolean {
    return (decodeURI(value) !== decodeURIComponent(value));
  }

  goBack(): void {
    if (this.prevParams) {
      if (this.containsEncodedComponents(this.prevParams)) {
        this.router.navigate([decodeURIComponent(this.prevParams)]);
      } else {
        this.router.navigate([this.prevParams]);
      }
    } else {
      history.back();
    }
  }

  setConfig(config:any): void {
    const { app, casinoLobby } = mergeConfigs(config, window.navigator.userAgent);

    this.gamePageConfig = {
      endpoint: app.endpoint,
      lang: app.language,
      datasources: casinoLobby.datasources,
      datasource: casinoLobby.datasources.default || '',
      mostPlayed: casinoLobby.featureFlag.mostPlayed,
      mostplayedrounds: casinoLobby.mostplayedrounds,
      favorites: casinoLobby.featureFlag.favorites,
      loginEvent: casinoLobby.loginEvent,
      registerEvent: casinoLobby.registerEvent,
      depositEvent: casinoLobby.depositEvent,
      haspanicbutton: casinoLobby.featureFlag.haspanicbutton,
      clockformat: casinoLobby.clockformat,
      activecategory: casinoLobby.activecategory,
      actionevent: casinoLobby.actionevent,
      integratedgameframedesktop: casinoLobby.featureFlag.integratedgameframedesktop,
      integratedgameframemobile: casinoLobby.featureFlag.integratedgameframemobile,
      clientstyling: customStyling
    };
  }

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    const params = { ...snapshot.queryParams };

    this.prevParams = params.prevRoute;

    if (isMobile(window.navigator.userAgent)) {
      this.router.navigate([this.prevParams]);
      window.postMessage({ type: 'ShowGameModal', gameId: this.gameID, isMobile: true}, window.location.href);
    }
  }
}
