import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { GamePageIntegratedConfig } from './game-page-integrated.type';

import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

import '@everymatrix/casino-integrated-game-page';
import { customStyling } from '../../custom-styles/game-page-integrated';

@Component({
  selector: 'app-game-page-integrated',
  templateUrl: './game-page-integrated.component.html',
  styleUrls: ['./game-page-integrated.component.scss']
})
export class GamePageIntegratedComponent implements OnInit {
  private sessionData: any;

  isLoggedIn: boolean = false;
  sessionID: string = '';
  userID: string = '';
  gameID: string = '';
  prevParams: string = '';

  gamePageIntegratedConfig: GamePageIntegratedConfig = {} as GamePageIntegratedConfig;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private localizationService: LocalizationService
  ) {
    this.setConfig(environment);

    this.gameID = this.route.snapshot.paramMap.get('gameID');

    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.gamePageIntegratedConfig.lang = selectedLang;

      if (this.gamePageIntegratedConfig.datasources[`${selectedLang}`]) {
        this.gamePageIntegratedConfig.datasource = this.gamePageIntegratedConfig.datasources[`${selectedLang}`];
      }
    });

    this.authService.isLoggedIn().subscribe((loggedIn: boolean) => {
      this.isLoggedIn = false;

      if (loggedIn) {
        this.sessionData = this.authService.getSession();
        this.sessionID = this.authService.getSessionID();

        this.userID = this.sessionData.UserID;
        this.isLoggedIn = true;
      } else {
        this.sessionID = '';
        this.userID = '';

        this.isLoggedIn = false;
      }
    });
  }

  goBack(): void {
    if (this.prevParams) {
      this.router.navigate([this.prevParams]);
    } else {
      history.back();
    }
  }

  setConfig(config:any): void {
    const { app, casinoLobby } = mergeConfigs(config, window.navigator.userAgent);

    this.gamePageIntegratedConfig = {
      endpoint: app.endpoint,
      lang: app.language,
      datasources: casinoLobby.datasources,
      datasource: casinoLobby.datasources.default || '',
      loginEvent: casinoLobby.loginEvent,
      registerEvent: casinoLobby.registerEvent,
      depositEvent: casinoLobby.depositEvent,
      haspanicbutton: casinoLobby.featureFlag.haspanicbutton,
      clockformat: casinoLobby.clockformat,
      activecategory: casinoLobby.activecategory,
      actionevent: casinoLobby.actionevent,
      gamebanneractive: casinoLobby.featureFlag.gamebanneractive,
      clientstyling: customStyling
    };
  }

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    const params = { ...snapshot.queryParams };

    this.prevParams = params.prevRoute;
  }
}
