import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { PlayerProfileConfig } from './player-profile.type';

import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

import { customStyling } from '../../custom-styles/player-profile';

import '@everymatrix/player-account-controller';

@Component({
  selector: 'app-player-profile',
  templateUrl: './player-profile.component.html',
  styleUrls: ['./player-profile.component.scss']
})
export class PlayerProfileComponent implements OnInit {
  private sessionData:any;

  isLoggedIn:boolean = false;
  sessionID:string = '';
  userID:string = '';
  userRoles:string = '';

  playerProfileConfig:PlayerProfileConfig = {} as PlayerProfileConfig;

  constructor(
    private authService: AuthService,
    private localizationService: LocalizationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.setConfig(environment);
  }

  setConfig(config:any): void {
    const { app, playerProfile } = mergeConfigs(config, window.navigator.userAgent);

    this.playerProfileConfig = {
      endpoint: app.endpoint,
      cmsEndpoint: app.cmsEndpoint,
      env: app.cmsEnv,
      lang: app.language,
      defaultRoute: app.defaultRoute,
      simplepasswordvalidation: app.simplepasswordvalidation,
      customNotification: playerProfile.customNotification,
      hasdefaultamount: playerProfile.featureFlag.hasdefaultamount,
      tablabels: playerProfile.tablabels,
      historyenabled: playerProfile.featureFlag.historyenabled,
      deletelimit: playerProfile.deletelimit,
      limitsamount: playerProfile.limitsamount,
      limitsperiods: playerProfile.limitsperiods,
      numberofentries: playerProfile.numberofentries,
      customcurrencysymbols: playerProfile.customcurrencysymbols,
      currency: playerProfile.currency,
      apiversion: playerProfile.apiversion,
      clientstyling: customStyling,
      operator: playerProfile.operator,
      supportEmail: playerProfile.supportEmail,
      productType: playerProfile.cashier?.productType,
      successUrl: playerProfile.cashier?.successUrl,
      cancelUrl: playerProfile.cashier?.cancelUrl,
      failUrl: playerProfile.cashier?.failUrl,
      sportsUrl: playerProfile.cashier?.sportsUrl,
      casinoUrl: playerProfile.cashier?.casinoUrl,
      contactUrl: playerProfile.cashier?.contactUrl,
      homeUrl: playerProfile.cashier?.homeUrl,
      channel: playerProfile.cashier?.channel,
      customInputTextSwitch: app.customInputTextSwitch, // used by playzonbet for switching input field texts and validation for register and player profile widgets: security question, answer, error messages and validation
      gmversion: app.gmversion,
      kycTitle: app.kycVerification.kycTitle,
      kycDescription: app.kycVerification.kycDescription
    };
  }

  setActivePage(url:string):void {
    switch (url) {
      case 'account/profile-info':
        window.postMessage({ type: 'SetActivePage', pageName: 'Profile Info', pagePath: '/profile-info', pageId: 0 }, window.location.href);
        break;

      case 'account/change-password':
        window.postMessage({ type: 'SetActivePage', pageName: 'Change password', pagePath: '/change-password', pageId: 1 }, window.location.href);
        break;

      case 'account/reality-check':
        window.postMessage({ type: 'SetActivePage', pageName: 'Reality Check', pagePath: '/reality-check', pageId: 2 }, window.location.href);
        break;

      case 'account/timeout':
        window.postMessage({ type: 'SetActivePage', pageName: 'Timeout', pagePath: '/timeout', pageId: 3 }, window.location.href);
        break;

      case 'account/account-closure':
        window.postMessage({ type: 'SetActivePage', pageName: 'Account Closure', pagePath: '/account-closure', pageId: 4 }, window.location.href);
        break;

      case 'account/gaming-limits':
        window.postMessage({ type: 'SetActivePage', pageName: 'Gaming Limits', pagePath: '/gaming-limits', pageId: 5 }, window.location.href);
        break;

      case 'account/self-exclusion':
        window.postMessage({ type: 'SetActivePage', pageName: 'Self Exclusion', pagePath: '/self-exclusion', pageId: 6 }, window.location.href);
        break;

      case 'account/active-bonuses':
        window.postMessage({ type: 'SetActivePage', pageName: 'Active Bonuses', pagePath: '/active-bonuses', pageId: 7 }, window.location.href);
        break;

      case 'account/bonus-history':
        window.postMessage({ type: 'SetActivePage', pageName: 'Bonus History', pagePath: '/bonus-history', pageId: 8 }, window.location.href);
        break;

      case 'account/gaming-history':
        window.postMessage({ type: 'SetActivePage', pageName: 'Gaming History', pagePath: '/gaming-history', pageId: 9 }, window.location.href);
        break;

      case 'account/deposit':
        window.postMessage({ type: 'SetActivePage', pageName: 'Deposit', pagePath: '/deposit', pageId: 10 }, window.location.href);
        break;

      case 'account/withdrawal':
        window.postMessage({ type: 'SetActivePage', pageName: 'Withdrawal', pagePath: '/withdrawal', pageId: 11 }, window.location.href);
        break;

      case 'account/pending-withdrawals':
        window.postMessage({ type: 'SetActivePage', pageName: 'Pending Withdrawals', pagePath: '/pending-withdrawals', pageId: 12 }, window.location.href);
        break;

      case 'account/transaction-history':
        window.postMessage({ type: 'SetActivePage', pageName: 'Transaction History', pagePath: '/transaction-history', pageId: 13 }, window.location.href);
        break;

      case 'account/betting-history':
        window.postMessage({ type: 'SetActivePage', pageName: 'Betting History', pagePath: '/betting-history', pageId: 14 }, window.location.href);
        break;

      case 'account/limits':
        window.postMessage({ type: 'SetActivePage', pageName: 'Limits', pagePath: '/limits', pageId: 15 }, window.location.href);
        break;

      case 'account/account-verification':
        window.postMessage({ type: 'SetActivePage', pageName: 'Account Verification', pagePath: '/account-verification', pageId: 16 }, window.location.href);
        break;

      case 'account/cashier-deposit':
        window.postMessage({ type: 'SetActivePage', pageName: 'Cashier Deposit', pagePath: '/cashier-deposit', pageId: 17 }, window.location.href);
        break;

      case 'account/cashier-withdrawal':
        window.postMessage({ type: 'SetActivePage', pageName: 'Cashier Withdrawal', pagePath: '/cashier-withdrawal', pageId: 18 }, window.location.href);
        break;

      case 'account/transactions-history':
        window.postMessage({ type: 'SetActivePage', pageName: 'Transactions History', pagePath: '/transactions-history', pageId: 19 }, window.location.href);
        break;

    }
  }

  ngOnInit():void {
    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.playerProfileConfig.lang = selectedLang;
    });

    this.authService.getListOfRoles().subscribe((data) => {
      this.userRoles = data;
    });

    this.route.paramMap.subscribe((params) => {
      this.localizationService.setLang(params.get('languageCode'), false);
    });

    this.authService.isLoggedIn().subscribe((loggedIn: boolean) => {
      if (loggedIn) {
        this.sessionData = this.authService.getSession();

        this.sessionID = this.sessionData.Guid;
        this.userID = this.sessionData.UserID;

        this.isLoggedIn = true;
      } else {
        this.sessionID = '';
        this.userID = '';

        this.isLoggedIn = false;
        if (this.router.url.includes('profile-info')) {
          this.router.navigate([`/${this.playerProfileConfig.lang}${this.playerProfileConfig.defaultRoute}`]);
        }
      }
    });

    const url = this.router.url.substring(0, this.router.url.indexOf('?') >= 0 ? this.router.url.indexOf('?') : this.router.url.length).split('/');
    this.setActivePage(url.splice(2, url.length).join('/'));

    this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd) {
        const playerProfileURL = this.router.url.substring(0, this.router.url.indexOf('?') >= 0 ? this.router.url.indexOf('?') : this.router.url.length).split('/');
        const urlWithoutLang = playerProfileURL.splice(2, playerProfileURL.length).join('/');

        this.setActivePage(urlWithoutLang);
      }
    });
  }
}
