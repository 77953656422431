<div class="live-lobby-container">
  <casino-lobby
    [attr.session]="sessionID"
    [attr.userid]="userID"
    [attr.endpoint]="liveLobbyConfig.endpoint"
    [attr.datasource]="liveLobbyConfig.datasource"
    [attr.winnersdatasources]="liveLobbyConfig.winnersdatasources"
    [attr.currency]="liveLobbyConfig.currency"
    [attr.lang]="liveLobbyConfig.lang"
    [attr.mostplayed]="liveLobbyConfig.mostPlayed"
    [attr.mostplayedrounds]="liveLobbyConfig.mostplayedrounds"
    [attr.lastplayed]="liveLobbyConfig.lastplayed"
    [attr.lastplayedperiod]="liveLobbyConfig.lastplayedperiod"
    [attr.mostplayedperiod]="liveLobbyConfig.mostplayedperiod"
    [attr.favorites]="liveLobbyConfig.favorites"
    [attr.loginevent]="liveLobbyConfig.loginEvent"
    [attr.registerevent]="liveLobbyConfig.registerEvent"
    [attr.depositevent]="liveLobbyConfig.depositEvent"
    [attr.clockformat]="liveLobbyConfig.clockformat"
    [attr.containermaxwidth]="liveLobbyConfig.containermaxwidth"
    [attr.haspanicbutton]="liveLobbyConfig.haspanicbutton"
    [attr.livecasino]="liveLobbyConfig.livecasino"
    [attr.activecategory]="liveLobbyConfig.activecategory"
    [attr.actionevent]="liveLobbyConfig.actionevent"
    [attr.gamemodal]="liveLobbyConfig.gamemodal"
    [attr.playrandomgame]="liveLobbyConfig.randomgame"
    [attr.integratedgameframedesktop]="liveLobbyConfig.integratedgameframedesktop"
    [attr.integratedgameframemobile]="liveLobbyConfig.integratedgameframemobile"
    [attr.gamepagemodalurl]="liveLobbyConfig.gamepagemodalurl"
    [attr.enableautoscroll]="liveLobbyConfig.enableautoscroll"
    [attr.visiblegames]="liveLobbyConfig.visiblegames"
    [attr.tabsorder]="liveLobbyConfig.tabsorder"
    [attr.isrecentavailable]="liveLobbyConfig.isrecentavailable"
    [attr.istopavailable]="liveLobbyConfig.istopavailable"
    [attr.enablecasinowinners]="liveLobbyConfig.enablecasinowinners"
    [attr.enablejackpotbanner]="liveLobbyConfig.enablejackpotbanner"
    [attr.clientstyling]="liveLobbyConfig.clientstyling"
    [attr.addfilterselector]="liveLobbyConfig.addfilterselector"
    [attr.addsortingselector]="liveLobbyConfig.addsortingselector"
    [attr.filterbylogo]="liveLobbyConfig.filterbylogo"
    [attr.casinogamesgridslider]="liveLobbyConfig.casinogamesgridslider"
  ></casino-lobby>
</div>
