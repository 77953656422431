<iframe
  [src]="sportsEndpoint"
  [ngStyle]="{'height': [height]}"
  [ngClass]="{'BetslipActive': betslipActive}"
  frameborder="0"
  id=”SportsBookIframe”
  class=”SportsIframe”
  title=”SportsIframe”
  scrolling="no"
  allow="autoplay;fullscreen"
  #iframe>
</iframe>

<casino-betslip-indicator
  (betslipClick)="goToBetslip()"
  [attr.clientstyling]="sportsConfig.style"
  [ngClass]="{'Hidden': betslipActive}"
></casino-betslip-indicator>
