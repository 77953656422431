import { Injectable } from '@angular/core';
import { ScriptStore } from '../external-scripts/index';

declare const document: any;

@Injectable({
  providedIn: 'root'
})

export class ExternalScriptInjectorService {

  private scripts: any = {};
    
  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.method,
        target: script.target
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(names: string) {
    const scriptsNames:string[] = names.split(',').map((item) => {
      return item.trim();
    });
    scriptsNames.forEach(scriptName => {
      return new Promise((resolve, reject) => {
        // if script is already loaded, proceed to resolve
        if (this.scripts[scriptName].loaded) {
          resolve({script: scriptName, loaded: true, status: 'Already Loaded'});
        } else {
          // load script
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = this.scripts[scriptName].src;
          if (script.readyState) { // ie
            script.onreadystatechange = () => {

              if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                this.scripts[scriptName].loaded = true;
                resolve({script: scriptName, loaded: true, status: 'Loaded'});
              }
            };
          } else {  // other browsers
            script.onload = () => {
              this.scripts[scriptName].loaded = true;
              resolve({script: scriptName, loaded: true, status: 'Loaded'});
            };
          }

          script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
          if (this.scripts[scriptName].target === 'head') {
            document.getElementsByTagName('head')[0].appendChild(script);
          } else {
            document.getElementsByTagName('body')[0].appendChild(script);
          }
        }
      });
    });
  }
}
