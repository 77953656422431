<casino-tournaments-limited-controller
  [attr.session]="sessionID"
  [attr.userid]="userID"
  [attr.currency]="currency"
  [attr.state]="toursMyLobbyConfig.state"
  [attr.states]="toursMyLobbyConfig.states"
  [attr.endpoint]="toursMyLobbyConfig.endpoint"
  [attr.lang]="toursMyLobbyConfig.lang"
  [attr.filter]="toursMyLobbyConfig.filter"
></casino-tournaments-limited-controller>
