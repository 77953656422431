import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';

import { CmsService } from './cms.service';

@Injectable()
// @TODO later maybe you should change the name (and file name) from resolver into something with load
export class CmsRoutesResolverService implements CanLoad {
  constructor(
    private cmsService: CmsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  canLoad(): boolean | Observable<boolean> {
    if (this.cmsService.routesFetched()) {
      return true;
    } else {
      return new Observable<boolean>((observer) => {
        this.cmsService.fetchRoutes().subscribe((res:any) => {
          this.cmsService.setRoutes(res);
          observer.next(true);
          observer.complete();
        });
      });
    }
  }
}
