import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  endpoint:string;
  userRoles:string = 'everyone';

  private authSubject = new BehaviorSubject<boolean>(this.hasToken());
  private rolesSubject = new BehaviorSubject<string>(this.userRoles);

  constructor() {
    this.setConfig(environment);
  }

  setConfig(config:any): void {
    const { app } = config;

    this.endpoint = app.endpoint;
  }

  /**
   * @returns {boolean}
   */
  private hasToken(): boolean {
    return !!localStorage.getItem('sessionID');
  }

  /**
   * @returns {Observable}
   */
  isLoggedIn(): Observable<boolean> {
    return this.authSubject.asObservable();
  }

  /**
   * @returns {Observable}
   */
  getListOfRoles(): Observable<string> {
    return this.rolesSubject.asObservable();
  }

  /**
   * @returns {string}
   */
  getSessionID(): string {
    const sessionID = localStorage.getItem('sessionID');

    if (sessionID != null) {
      return sessionID;
    }

    return null;
  }

  setSessionID(sessionID:string): void {
    localStorage.setItem('sessionID', sessionID);

    this.checkSession();
  }

  deleteSessionID(): void {
    localStorage.removeItem('sessionID');

    this.deleteSession();

    this.authSubject.next(false);
  }

  /**
   * @returns {string}
   */
  getSession(): string {
    const session = localStorage.getItem('session');

    if (session != null) {
      return JSON.parse(session);
    }

    return '';
  }

  setSession(session:string): void {
    localStorage.setItem('session', session);
  }

  deleteSession(): void {
    localStorage.removeItem('session');
  }

  checkSession(): void {
    const headers = new Headers();

    if (this.getSessionID() == null) {
      this.authSubject.next(false);
    } else {
      headers.append('X-SessionID', this.getSessionID());

      const options = {
        method: 'GET',
        headers
      };

      fetch(`${this.endpoint}/v1/player/session/player`, options)
        .then((res: any) => {
          if (res.status >= 300) {
            this.rolesSubject.next('everyone');
            this.authSubject.next(false);
            return false;
          }

          return res.json();
        })
        .then((data: any) => {
          if (data == false || data.isAuthenticated == false) {
            this.deleteSessionID();
            this.rolesSubject.next('everyone');
            this.authSubject.next(false);
          } else {
            this.rolesSubject.next('everyone,loggedIn,' + data.Roles?.join(','));
            this.setSession(JSON.stringify(data));
            this.authSubject.next(true);
          }
        });
    }
  }
}
