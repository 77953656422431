import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../../services/localization.service';

import { RegisterConfig } from './register-component.type';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  language: string = 'en';
  registerConfig: RegisterConfig = {} as RegisterConfig;

  constructor(
    private router:Router,
    private localizationService: LocalizationService,
    private route:ActivatedRoute
  ) {
    this.setConfig(environment);

    this.localizationService.currentLang().subscribe((selectedLang: string) => {
      this.language = selectedLang;
    });
  }

  setConfig(config:any): void {
    const { app } = mergeConfigs(config, window.navigator.userAgent);

    this.registerConfig = {
      defaultRoute: app.defaultRoute
    };
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params:any) => {
      this.localizationService.setLang(params.get('languageCode'));
    });

    this.router.navigate([`/${this.language}/${this.registerConfig.defaultRoute}`]);

    window.postMessage({
      type: 'OpenLoginRegisterModal',
      transition: 'Register'
    }, window.location.href);
  }

}
