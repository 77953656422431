import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { VirtualSportsConfig } from './virtual-sports-lobby.type';

import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';
import { CmsService } from 'src/app/services/cms.service';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';

import { customStyling } from '../../custom-styles/virtual-sports-lobby';

import '@everymatrix/casino-lobby';

@Component({
  selector: 'app-virtuals-sports-lobby',
  templateUrl: './virtuals-sports-lobby.component.html',
  styleUrls: ['./virtuals-sports-lobby.component.scss']
})
export class VirtualsSportsLobbyComponent implements OnInit {
  private sessionData:any;

  isLoggedIn:boolean = false;
  sessionID:string = '';
  userID:string = '';

  virtualSportsConfig:VirtualSportsConfig = {} as VirtualSportsConfig;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private localizationService: LocalizationService,
    private cmsService: CmsService
  ) {
    this.setConfig(environment);

    window.addEventListener('message', this.messageHandler, false);

    this.localizationService.currentLang().subscribe((selectedLang:string) => {
      this.virtualSportsConfig.lang = selectedLang;

      if (this.virtualSportsConfig.fetchCMSDatasource) {
        this.getCMSDataSource(this.virtualSportsConfig.datasources.default);
      } else {
        if (this.virtualSportsConfig.datasources[`${selectedLang}`]) {
          this.virtualSportsConfig.datasource = this.virtualSportsConfig.datasources[`${selectedLang}`] || this.virtualSportsConfig.datasources['default'];
        }
      }
    });

    this.authService.isLoggedIn().subscribe((loggedIn: boolean):void => {
      this.isLoggedIn = false;

      if (loggedIn) {
        this.sessionData = this.authService.getSession();

        this.sessionID = this.authService.getSessionID();
        this.userID = this.sessionData.UserID;

        this.isLoggedIn = true;
      } else {
        this.sessionID = '';
        this.userID = '';

        this.isLoggedIn = false;
      }
    });

    this.virtualSportsConfig.activecategory = this.router.url.split('/')[3] || 'LOBBY';
  }

  messageHandler = (e: any): void =>  {
    if (e.data && e.data.type == this.virtualSportsConfig.actionevent) {
      this.router.navigate([e.data.itemId], { relativeTo: this.route });
    }

    if (e.data && e.data.type == 'UpdateSelectedVendorFilters') {
      /*this.router.navigate([], {
        queryParams: {
          gameID: null,
        },
        queryParamsHandling: 'merge',
      })*/
    }
  };

  getCMSDataSource(baseDS:any): void {
    this.cmsService.getDatasource('virtuals').then((datasource:string | any):void => {
      this.virtualSportsConfig.datasource = datasource;
    }).catch(() => {
      this.virtualSportsConfig.datasource = baseDS; // if the call fails, use the default DS
    });
  }

  setConfig(config:any): void {
    const { app, virtualSportsLobby } = mergeConfigs(config, window.navigator.userAgent);

    this.virtualSportsConfig = {
      endpoint: app.endpoint,
      lang: app.language,
      identity: app.identity,
      fetchCMSDatasource: app.fetchCMSDatasource,
      datasources: virtualSportsLobby.datasources,
      winnersdatasources: virtualSportsLobby.winnersdatasources,
      mostPlayed: virtualSportsLobby.featureFlag.mostPlayed,
      mostplayedrounds: virtualSportsLobby.mostplayedrounds,
      lastplayed: virtualSportsLobby.featureFlag.lastplayed,
      lastplayedperiod: virtualSportsLobby.lastplayedperiod,
      mostplayedperiod: virtualSportsLobby.mostplayedperiod,
      favorites: virtualSportsLobby.featureFlag.favorites,
      loginEvent: virtualSportsLobby.loginEvent,
      registerEvent: virtualSportsLobby.registerEvent,
      depositEvent: virtualSportsLobby.depositEvent,
      lobbyID: virtualSportsLobby.lobbyID,
      containermaxwidth: virtualSportsLobby.containermaxwidth,
      haspanicbutton: virtualSportsLobby.featureFlag.haspanicbutton,
      clockformat: virtualSportsLobby.clockformat,
      activecategory: virtualSportsLobby.activecategory,
      actionevent: virtualSportsLobby.actionevent,
      gamemodal: virtualSportsLobby.featureFlag.gamemodal,
      randomgame: virtualSportsLobby.featureFlag.randomgame,
      playforfun: virtualSportsLobby.featureFlag.playforfun,
      visiblegames: virtualSportsLobby.visiblegames,
      integratedgameframedesktop: virtualSportsLobby.integratedgameframedesktop,
      integratedgameframemobile: virtualSportsLobby.integratedgameframemobile,
      gamepagemodalurl: virtualSportsLobby.featureFlag.gamepagemodalurl,
      showsubgroups: virtualSportsLobby.featureFlag.showsubgroups,
      clientstyling: customStyling,
      addfilterselector: virtualSportsLobby.featureFlag.addfilterselector,
      addsortingselector: virtualSportsLobby.featureFlag.addsortingselector,
      filterbylogo: virtualSportsLobby.featureFlag.filterbylogo,
      casinogamesgridslider: virtualSportsLobby.casinogamesgridslider
    };

    // if the ff is on, make the call for the DS, else use the default from the env
    if (this.virtualSportsConfig.fetchCMSDatasource) {
      this.getCMSDataSource(virtualSportsLobby.datasources.default);
    } else {
      this.virtualSportsConfig.datasource = virtualSportsLobby.datasources.default;
    }
  }

  ngOnInit(): void {
    //Banner matrix fix when changing page from profile section
    window.postMessage({
      type: 'BMFE:sliderRefresh',
      payload: {}
    }, '*');

    this.route.paramMap.subscribe((params: any): void => {
      this.localizationService.setLang(params.get('languageCode'), false);
    });

    this.router.events
      .subscribe((event: any): void => {
        if ( event instanceof NavigationEnd ) {
          this.virtualSportsConfig.activecategory = this.router.url.split('/')[3] || 'LOBBY';
        }
      });
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.messageHandler);
  }
}
