import { Component, OnInit } from '@angular/core';
import { WheelOfFortuneConfig } from './wheel-of-fortune.type';

import '@everymatrix/lottery-program-wof';

import { environment } from './../../../environments/environment';
import { mergeConfigs } from '../../../utils/utils';
import { LocalizationService } from '../../services/localization.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-wheel-of-fortune',
  templateUrl: './wheel-of-fortune.component.html',
  styleUrls: ['./wheel-of-fortune.component.scss']
})
export class WheelOfFortuneComponent implements OnInit {
  wheelOfFortuneConfig:WheelOfFortuneConfig = {} as WheelOfFortuneConfig;

  isLoggedIn: boolean = false;
  sessionID:string = '';

  constructor(
    private router: Router,
    private localizationService: LocalizationService,
    private authService: AuthService
  ) {
    this.setConfig(environment);

    this.authService.isLoggedIn().subscribe((loggedIn:boolean) => {
      if (loggedIn == true) {

        this.sessionID = this.authService.getSessionID();

        this.isLoggedIn = true;

      } else {
        this.sessionID = '';
        this.isLoggedIn = false;
      }
    });

    this.localizationService.currentLang().subscribe((selectedLang:string) => {
      this.wheelOfFortuneConfig.lang = selectedLang;
    });

   }

  setConfig(config:any): void {
    const { app } = mergeConfigs(config, window.navigator.userAgent);

    this.wheelOfFortuneConfig = {
      endpoint : app.endpointCE,
      lang: app.language
    };
  }

  ngOnInit(): void {
  }

}
