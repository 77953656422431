/**
 * Custom styling variable defined here for app.component.ts that is passed to all widgets included in app.component.html
 * A better segregation can be done here in the future
 */

export const customStyling:string = `
.HeaderWrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.HeaderMobileWrapper {
  .HeaderContainer {
    height: 55px;
  }
}
.NavigationWrapper {
  height: 60px;
}
.NavigationBar {
  border-radius: 5px 5px 0 0;
}
.NavigationPanel {
  width: 100%;
  position: fixed;
  bottom: 0;
}
.HamburgerModalWindow .HamburgerPrimaryMenu { background-color: var(--emfe-w-color-gray-600); }
.HamburgerModalWindow .HamburgerModalContainer { background-color: var(--emfe-w-color-black); }
.LanguageSelectorTitle { background-color: var(--emfe-w-color-black) }
`;
