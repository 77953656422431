import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from './../environments/environment';
import { isMobile, mergeConfigs } from '../utils/utils';

import { AuthService } from './services/auth.service';
import { LocalizationService } from './services/localization.service';
import { NotificationService } from './services/notification.service';
import { ExternalScriptInjectorService } from './services/external-script-injector.service';
import { ScriptStore } from './external-scripts/index';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { customStyling } from './custom-styles/app';
import { MetadataConfig } from './external-scripts/meta-tag-data';

import '@everymatrix/casino-header-controller';
import '@everymatrix/casino-footer';
import '@everymatrix/casino-footer-v2';
import '@everymatrix/general-navigation-bar';
import '@everymatrix/casino-betslip-indicator';
import '@everymatrix/user-action-controller/dist/esm/user-action-controller';
import '@everymatrix/general-tutorial-slider/dist/esm/general-tutorial-slider';
import '@everymatrix/user-actions/dist/esm/user-actions';

declare let gtag: Function;
declare let window: any; // required in order to avoid ts errors for window.trackJS; @TODO should define a better property type for it

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  isLoggedIn:boolean = false;
  playerActions:any = {};
  showPlayerActions:boolean = false;
  isMobileDevice:boolean;
  sessionID:string;
  userID:string;
  userRoles:string = '';

  btag:string = '';
  showBanners:boolean;
  forgotPasswordKey:string;

  config:any = {};
  displayFooter: boolean = true;
  private subscriptions: Subscription[] = [];
  scriptList:string = '';
  firstAccess: boolean = true;
  lastLogin: string = '';
  showTutorialWidget: any = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private localizationService: LocalizationService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private location: Location,
    private externalScriptInjectorService: ExternalScriptInjectorService,
    private translate: TranslateService,
    private metaService: Meta
  ) {
    this.isMobileDevice = isMobile(window.navigator.userAgent);

    this.setConfig(environment);
    ScriptStore.forEach(script => {
      this.scriptList += !this.scriptList ? script.name : ', ' + script.name;
    });

    this.externalScriptInjectorService.load(this.scriptList).catch(error => console.log(error));

    if (this.config.bannersEnabled) {
      this.loadBannerMatrixScripts();
    }

    if (this.config.chatBubbleEnabled) {
      // @TODO move this through a scriptService or something and remove the hardcoded operator-specific code from master
      window.callbellSettings = {
        token: 'mep5VYpC9hu9p9PL7mShAJod'
      };
      // @ts-ignore: Unreachable code error
      (function(){var w=window;var ic=w.callbell;if(typeof ic==="function"){ic('reattach_activator');ic('update',window.callbellSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Callbell=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://dash.callbell.eu/include/'+window.callbellSettings.token+'.js';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();  // eslint-disable-line

      this.hideElementFromGamePages('callbell-iframe');
    }

    this.hideElementFromGamePages('ThemeToggle');

    window.addEventListener('message', this.messageHandler.bind(this));

    this.authService.getListOfRoles().subscribe((data) => {
      this.userRoles = data;
    });

    this.localizationService.currentLang().subscribe((selectedLang:string) => {
      this.config.language = selectedLang;
      this.translate.use(this.config.language);
    });

    document.addEventListener('visibilitychange', this.visibilityChangeHandler.bind(this));

    this.authService.isLoggedIn().subscribe((loggedIn:boolean) => {
      if (loggedIn == true) {
        const data:any = this.authService.getSession();
        this.lastLogin = data.LastLogin;
        this.sessionID = this.authService.getSessionID();
        this.userID = data.UserID;
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }

      if (this.config.displayTutorialWidget ) {
        if (this.isLoggedIn) {
          if (this.lastLogin === '0001-01-02T00:00:00') {
            if (localStorage.getItem('tutorialWidget') !== 'false') {
              this.showTutorialWidget = true;
              window.postMessage({ type: 'DisableScroll'}, window.location.href);      
            }
          }
        }
      }  
    });

    if (this.config.trackjsEnabled) {
      this.loadTrackJSScript();
    }

    this.translate.setDefaultLang(this.config.language);

  }

  visibilityChangeHandler(): void { // on tab change, check session in order to sync session on components and update page status if needed
    if (document.visibilityState === 'visible') {
      if (!this.firstAccess) {
        // needed to reinstate the new session across iframes
        this.authService.checkSession();
      }
      this.firstAccess = false;
    }
  }

  back(): void {
    this.location.back();
  }

  setActiveRoute(eventUrl:string):void {
    if (eventUrl && eventUrl.length > 3) {
      let url = eventUrl.split('/')[2];
      if (eventUrl.split('/')[3]) {
        url = eventUrl.split('/')[2] + '/' + eventUrl.split('/')[3];
      } else {
        url = eventUrl.split('/')[2];
      }
      this.config.activeRoute = '/' + url.substr(0, eventUrl.indexOf('?') > 0 ? eventUrl.indexOf('?') : eventUrl.length);
    }
  }

  userLogOut():void {
    localStorage.removeItem('tutorialWidget');
    this.authService.deleteSessionID();
    this.router.navigate([`/${this.config.language}${this.config.defaultRoute}`]);
    this.notificationService.handle({ type: 'info', message: 'You have been successfully logged out' });
  }

  loadTrackJSScript():void {
    const scriptNode = document.createElement('script');

    let config:any = {
      token: this.config.trackjsID,
      application: this.config.applicationName,
      version: '1.0.0',
      window: { promise: true }
    };

    if (this.isLoggedIn) {
      config = {
        userId: `${this.userID}`,
        sessionId: `${this.userID} - ${new Date()}`,
        ...config
      };
    }

    scriptNode.src = 'https://cdn.trackjs.com/agent/v3/latest/t.js';
    scriptNode.onload = () => {
      window.TrackJS && window.TrackJS.install(config);
      window.TrackJS.track();
    };

    document.getElementsByTagName('head')[0].appendChild(scriptNode);
  }

  loadBannerMatrixScripts():void {
    const scriptNode = document.createElement('script');
    const linkNode = document.createElement('link');

    linkNode.href = '/assets/index.html';
    linkNode.rel = 'import';

    scriptNode.src = '/assets/webcomponents-lite.js';

    document.getElementsByTagName('head')[0].appendChild(linkNode);
    document.getElementsByTagName('head')[0].appendChild(scriptNode);
  }


  hideElementFromGamePages(elementId: string ) {
    // Observe the DOM for the element to be appended
    const observer = new MutationObserver((mutations, observer) => {

      for (const mutation of mutations) {

        if (mutation.addedNodes.length) {
          const element = document.getElementById(elementId);
          if (element) {
            element.style.zIndex = '0';
            observer.disconnect();

            const subscription = this.router.events.subscribe((event:any) => {
              if (event instanceof NavigationEnd) {
                element.style.zIndex = (event.url.includes('play-game') || event.url.includes('game-page')) ? '-1' : '0';
              }
            });
            this.subscriptions.push(subscription);
          }
        }
      }
    });

    observer.observe(document, { childList: true, subtree: true });
  }

  setConfig(config:any): void {
    const {
      app,
      casinoLobby,
      liveCasinoLobby,
      virtualSportsLobby,
      tournamentsLobby,
      promotions,
      homePage,
      sports
    } = mergeConfigs(config, window.navigator.userAgent);

    this.config = {
      endpoint: app.endpoint,
      cmsEndpoint: app.cmsEndpoint,
      env: app.cmsEnv,
      defaultRoute: app.defaultRoute,
      language: app.language,
      languageslist: app.languagesList,
      themeToggleEnabled: app.featureFlag.themeToggleEnabled,
      savecredentials: app.featureFlag.savecredentials,
      usePostmessage: app.featureFlag.usePostmessage,
      gtagManagerID: app?.gtagManagerID || '',
      enableGTMTracking: app?.featureFlag.enableGTMTracking || '',
      gtmMetaContent: app?.gtmMetaContent || false,
      enableUserActions: app.featureFlag.enableUserActions,
      show: false,
      consentEnabled: app.userConsents.enabled,
      userConsents: app.userConsents,
      displayTutorialWidget: app.featureFlag.displayTutorialWidget,
      customInputTextSwitch: app.customInputTextSwitch,  // used by playzonbet for switching input field texts and validation for register and player profile widgets: security question, answer, error messages and validation
      displayBalance: {
        separator: app.displayBalance?.separator,
        decimal: app.displayBalance?.decimal,
        precision: app.displayBalance?.precision
      },
      bannermatrix: {
        operatorID: app.bannermatrix.operatorID,
        realm: app.bannermatrix.realm,
        webapi: app.bannermatrix.webapi,
        bettingoffers: app.bannermatrix.bettingoffers,
        demo: app.bannermatrix.demo,
        pagesWithoutBM: app.bannermatrix.pagesWithoutBM
      },
      tutorial:{
        showSliderDots: app.tutorial?.showSliderDots,
        showSliderArrows: app.tutorial?.showSliderArrows,
        showSliderArrowsMobile: app.tutorial?.showSliderArrowsMobile,
        enableAutoScroll: app.tutorial?.enableAutoScroll,
        intervalPeriod: app.tutorial?.intervalPeriod,
        scrollItems: app.tutorial?.scrollItems,
        itemsPerPage: app.tutorial?.itemsPerPage
      },
      footer: {
        footerversion: app.footer.footerversion,
        clockenabled: app.footer.clockenabled,
        clocksecondsenabled: app.footer.clocksecondsenabled,
        clockcustomformat: app.footer.clockcustomformat,
        clocktext: app.footer.clocktext,
        panicbuttonenabled: app.footer.panicbuttonenabled,
        languagedisplaytype: app.footer.languagedisplaytype,
        target: app.footer.target,
        languageselectorenabled: app.footer.languageselectorenabled,
        sessiontimerenabled: app.footer.sessiontimerenabled
      },
      operatorID: app.operatorID,
      gtag: app.gtag,
      trackjsEnabled: app.featureFlag.trackjsEnabled,
      chatBubbleEnabled: app.featureFlag.chatBubbleEnabled,
      chatBubbleID: app.chatBubbleID,
      applicationName: app.applicationName,
      trackjsID: app.trackjsID,
      bannersEnabled: app.featureFlag.bannersEnabled,
      mobileBottomNavigationEnabled: app.featureFlag.mobileBottomNavigationEnabled,
      licenseyears: app.licenseyears,
      smsverification: app.featureFlag.smsverification,
      simplepasswordvalidation: app.featureFlag.simplepasswordvalidation,
      actionevent: 'HeaderMenuItemClicked',
      activeRoute: app.activeRoute,
      displayGamePageOnly: false,
      disabledFields: app.registerDisabledFields,
      defaultOptions: app.registerDefaultOptions,
      customLocaleIdentifier: app.customLocaleIdentifier,
      displaybalanceoption: app.displaybalanceoption,
      gmversion: app.gmversion,
      countryflagheader: app.featureFlag.countryflagheader,
      countryflaghamburger: app.featureFlag.countryflaghamburger,
      isIntegratedGameFrameDesktop: casinoLobby.featureFlag.integratedgameframedesktop,
      isIntegratedGameFrameMobile: casinoLobby.featureFlag.integratedgameframemobile,
      datasource: casinoLobby.datasources.default,
      style: customStyling,
      origin: window.location.origin,
      sportOMVersion: sports.versionOfOMIframe,
      casinoLobby: {
        pagetag: casinoLobby.bannermatrix.pagetag,
        positiontag: casinoLobby.bannermatrix.positiontag
      },
      liveCasinoLobby: {
        pagetag: liveCasinoLobby.bannermatrix.pagetag,
        positiontag: liveCasinoLobby.bannermatrix.positiontag
      },
      virtualSportsLobby: {
        pagetag: virtualSportsLobby.bannermatrix.pagetag,
        positiontag: virtualSportsLobby.bannermatrix.positiontag
      },
      tournamentsLobby: {
        pagetag: tournamentsLobby.bannermatrix.pagetag,
        positiontag: tournamentsLobby.bannermatrix.positiontag
      },
      promotions: {
        pagetag: promotions.bannermatrix.pagetag,
        positiontag: promotions.bannermatrix.positiontag
      },
      homePage: {
        pagetag: homePage?.bannermatrix.pagetag,
        positiontag: homePage?.bannermatrix.positiontag
      }
    };
  }

  containsEncodedComponents(value:string):boolean {
    return (decodeURI(value) !== decodeURIComponent(value));
  }

  messageHandler(e:any):void {
    let subs:any;
    let urlKey:string;

    if (e.data) {
      switch (e.data.type) {
        case 'PlayerAccountMenuActive':
          subs = this.route.queryParams.subscribe((params:any) => {
            if (Object.keys(params).length == 0) {
              if (e.data.isMobile) {
                this.router.navigate([`/${this.config.language}/account`], {
                  queryParams: {
                    prevRoute: this.router.url
                  }
                });
              } else {
                this.router.navigate([`/${this.config.language}/account/profile-info`], {
                  queryParams: {
                    prevRoute: this.router.url
                  }
                });
              }
            } else {
              if (e.data.isMobile) {
                this.router.navigate([`/${this.config.language}/account`], {
                  queryParams: {
                    prevRoute: params.prevRoute
                  }
                });
              } else {
                this.router.navigate([`/${this.config.language}/account/profile-info`], {
                  queryParams: {
                    prevRoute: params.prevRoute
                  }
                });
              }
            }
          });

          subs.unsubscribe();
          break;

        case 'UserSessionID':
          this.authService.setSessionID(e.data.session);
          this.sessionID = e.data.session;
          this.userID = e.data.userid;
          this.isLoggedIn = true;
          break;

        case 'PlayerActions':
          this.playerActions = e.data.data;

          this.config = {
            playerActions: this.playerActions[0].action.join(','),
            ...this.config
          };

          if (this.config.consentEnabled) {
            this.showPlayerActions = true;
          }
          break;

        case 'UserActionsCompleted':
          this.showPlayerActions = false;
          break;

        case 'closeButtonClicked':
          this.showPlayerActions = false;
          break;

        case 'LogoutSuccessfull':
          this.userLogOut();
          break;

        case 'TournamentDetailsClicked':
          this.router.navigate([`/${this.config.language}/tournament/${e.data.tournamentId}`]);
          break;

        case 'GotoFromTournamentsPage':
          switch (e.data.pageType) {
            case 'Casino': urlKey= 'casino';break;
            case 'Sports': urlKey= 'virtuals';break;
            default: urlKey='mytournaments';
          }

          this.router.navigate([`/${this.config.language}/${urlKey}`]);
          break;

        case 'MyTournamentFromNavClicked':
          this.router.navigate([`/${this.config.language}/tournament/${e.data.tournamentId}`]);
          break;

        case 'LanguageChanged':
          this.localizationService.setLang(e.data.selectedLanguage);
          break;

        case 'GoToHomepage':
          this.router.navigate([`/${this.config.language}${this.config.defaultRoute}`]);
          break;

        case 'TournamentGameAction':
          this.router.navigate([`/${this.config.language}/play-game/${e.data.gameId}`], {
            queryParams: {
              prevRoute: this.router.url
            }
          });
          break;

        case 'GoToSports':
          this.router.navigate([`/${this.config.language}/sport`]);
          break;

        case 'GoToCasino':
          this.router.navigate([`/${this.config.language}/casino`]);
          break;

        case 'GoToDeposit':
          if (this.router.url.split('?')[0].includes('deposit')) return;
          this.router.navigate([`/${this.config.language}/account/deposit`], {
            queryParams: {
              prevRoute: this.router.url
            }
          });
          break;

        case 'ChangeAccountPage':
          e.data.page.attrs.target == undefined ? null : e.data.page.attrs.target;

          if (e.data.page.externalLink == false) {
            if (e.data.page.target == null) {
              subs = this.route.queryParams.subscribe((params:any) => {
                if (params) {
                  this.router.navigate([`/${this.config.language}/account${e.data.page.path}`], {
                    queryParams: {
                      // Previous route for deposit should include last two previous routes
                      prevRoute: e.data.page.path == '/deposit' ? this.router.url : params.prevRoute
                    }
                  });
                }
              });
              subs.unsubscribe();
            }

            if (e.data.page.attrs.target == '_blank') {
              window.open(`/${this.config.language}/account${e.data.page.path}`, e.data.page.attrs.target);
            }
          }

          if (e.data.page.externalLink == true) {
            if (e.data.page.attrs.target == null) {
              window.location.href=`${e.data.page.path}`;
            }

            if (e.data.page.attrs.target == '_blank') {
              window.open(`${e.data.page.path}`, e.data.page.attrs.target);
            }
          }
          break;

        case 'DisableScroll':
          if (window.navigator.userAgent.toLowerCase().match(/iphone/i) || window.navigator.userAgent.toLowerCase().match(/ipad|ipod/i)) {
            document.body.classList.add('ScrollBehindOffIOS');
          } else {
            document.body.classList.add('scroll-behind-off');
          }
          break;

        case 'EnableScroll':
            document.body.classList.remove('ScrollBehindOffIOS');
            document.body.classList.remove('scroll-behind-off');
          break;

        case 'WidgetNotification':
          this.notificationService.handle(e.data.data);
          break;

        case 'PanicButtonClicked':
          this.notificationService.handle({ type: 'info', message: 'Panic button clicked' });
          break;

        case 'NavigateTo':
          if (e.data.externalLink) {

            if (e.data.target !== '_blank') {
              window.location.href=`${e.data.path}`;
            } else {
              window.open(`${e.data.path}`, e.data.target);
            }
          } else {
            this.config.activeRoute = e.data.path;

            if (e.data.target !== '_blank') {
              this.router.navigate([`/${this.config.language}/${this.clearPathOfLang(e.data.path)}`]);
            } else {
              window.open(`${e.data.path}`, e.data.target);
            }
          }
          break;
          
        case 'OnCloseThumbnail':
          this.back();
          break;

        case 'GoToPreviousRoute':
          window.postMessage({ type: 'EnableScroll'}, window.location.href);

          subs = this.route.queryParams.subscribe((params:any) => {
            if (params && params.prevRoute) {
              if (this.containsEncodedComponents(params.prevRoute)) {
                this.router.navigateByUrl(decodeURIComponent(params.prevRoute));
              } else {
                this.router.navigateByUrl(params.prevRoute);
              }
            } else {
              this.back();
            }
          });
          subs.unsubscribe();
          break;


        case 'OpenIntegratedGameFrame':
          this.router.navigate([`/${this.config.language}/game-page/${e.data.gameId}`], {
            queryParams: {
              prevRoute: this.router.url
            }
          });
          this.config.displayGamePageOnly = true;
          break;

        case 'DisableIntegratedGamePage':
          this.config.displayGamePageOnly = false;
          break;

        case '403ActionButton':
          if (this.isLoggedIn) {
            this.notificationService.handle({
              type: 'info',
              message: 'You are already logged in'
            });
          } else {
            window.postMessage({
              type: 'OpenLoginRegisterModal',
              transition: 'Login'
            }, window.location.href);
          }
          break;

        case 'ShowGameModal':
          if ((!e.data.isMobile && this.config.isIntegratedGameFrameDesktop == 'true') || (e.data.isMobile && this.config.isIntegratedGameFrameMobile == 'true')) {
            this.router.navigate([`/${this.config.language}/game-page/${e.data.gameId}`], {
              queryParams: {
                prevRoute: decodeURIComponent(this.router.url)
              }
            });
          }
          break;

        case 'OpenGameFrame':
          if (e.data.gamepagemodalurl == 'true') {
            this.router.navigate([`/${this.config.language}/play-game/${e.data.gameId}`], {
              queryParams: {
                prevRoute: decodeURIComponent(this.router.url)
              }
            });
          }
          break;

        case 'handlePromotionPage':
            if (e.data.pageHandler == 'open') {
              this.router.navigate([`/${this.config.language}/promotions/${e.data.dataPromotion.slug}`]);
            } else if (e.data.pageHandler == 'back') {
              this.router.navigate([`/${this.config.language}/promotions`]);
            }
          break;

        case 'OpenCasinoWinnersGame':
          // @TODO the identification by id should be changed to vendorCategory when NorWAy supports it.
          // Handle the case when the win is a sports betting win, identified by the 14923 game id.
          if (e.data.gameId == '14923') {
            this.router.navigate([`/${this.config.language}/sport`]);
          } else {
            this.router.navigate([`/${this.config.language}/play-game/${e.data.gameId}`]);
            this.config.displayGamePageOnly = false;
          }
          break;


        case 'ModalClosed':
          window.postMessage({ type: 'EnableScroll'}, window.location.href);
          if (e.data.gamepagemodalurl == 'true') {
            if (e.data.mobileView) {
              this.location.replaceState(`${this.router.url}`);
            }
          }
          break;


        case 'OpenGameCategory':
          this.router.navigate([`/${this.config.language}/casino/${e.data.categoryId}`]);
          break;

        case 'OpenGameProvider':
          sessionStorage.setItem('vendorFiltersByCategory', JSON.stringify({
            [`${this.config.datasource}$all-games`] : [`${e.data.providerId}`]
          }));

          this.router.navigate([`/${this.config.language}/casino/${this.config.datasource}$all-games`]);
          break;

        case 'OpenCollectionsProvidersCategory':
          this.router.navigate([`/${this.config.language}/casino/${e.data.categoryId}`]);
          break;

        case 'OpenCollectionsProvidersGame':
          this.router.navigate([`/${this.config.language}/play-game/${e.data.gameId}`], {
            queryParams: {
              prevRoute: this.router.url
            }
          });
          break;

        case 'NonExistentCategory':
          subs = this.router.events.subscribe((event:any) => {
            if (event instanceof NavigationEnd) {
              this.router.navigate([`/${this.config.language}/404-not-found`]);
            }
          });

          subs.unsubscribe();
          break;
      }
    }
  }

  clearPathOfLang(path: string) {
    const langPattern = /^(?:\/)?([a-z]{2}(?:-[a-z]{2})?)(?=\/)/;
    if (langPattern.test(path)) {
        path = path.replace(langPattern, '');
    }
    return path;
  }

  setForgotPasswordKey(key:string):void {
    this.forgotPasswordKey = key;
  }

  closeTutorialWidget(): void {
    this.showTutorialWidget = false;
    localStorage.setItem('tutorialWidget', this.showTutorialWidget);
    window.postMessage({ type: 'EnableScroll'}, window.location.href);
  }

  ngOnDestroy():void {
    window.removeEventListener('message', this.messageHandler);
    window.removeEventListener('visibilitychange', this.visibilityChangeHandler);

    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit():void {

    if (this.config.enableGTMTracking) {
      // Add gtm noscript tag
      const gtmNoScript = document.createElement('noscript');
      const gtmIframe = document.createElement('iframe');

      gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${this.config.gtagManagerID}`;
      gtmIframe.width = '0';
      gtmIframe.height = '0';

      gtmNoScript.appendChild(gtmIframe);
      document.getElementsByTagName('body')[0].appendChild(gtmNoScript);

      // Add gtm meta tag
      this.metaService.addTag( { name:'google-site-verification', content: this.config.gtmMetaContent});
    }

    this.router.events.subscribe((event:any) => {
      // Handles if the BM should be displayed or not
      if (event instanceof NavigationEnd) {
        gtag('config', this.config.gtag, { page_path: event.urlAfterRedirects });

        if (this.config.bannermatrix.pagesWithoutBM.some((page:string) => event.url.includes(page) || event.urlAfterRedirects.includes(page))) {
          this.showBanners = false;
        } else {
          this.showBanners = true;
        }

        // This is needed to set active item in header's menu
        this.setActiveRoute(event.urlAfterRedirects); // Using .urlAfterRedirects as the .url is empty when navigating to the base url of the site, and would always default to casino no matter the default set path set from env

        this.config.displayGamePageOnly = this.router.url.includes('/game-page/') ? true : false;

        // Do not display footer for OMFE sports Iframe version 2 or 3.
        if (this.config.sportOMVersion == 2) {
          this.displayFooter = this.router.url.includes('/sport') ? false : true;
        }

        this.route.queryParams.subscribe(params => {
          this.btag = params['btag'] || '';

          Object.keys(params).forEach((item:any) => {
            const snapshot = this.route.snapshot;
            const params = { ...snapshot.queryParams };

            if (item == 'login') {
              if (this.isLoggedIn) {
                this.notificationService.handle({
                  type: 'info',
                  message: 'You are already logged in'
                });
              } else {
                window.postMessage({
                  type: 'OpenLoginRegisterModal',
                  transition: 'Login'
                }, window.location.href);
              }

              delete params.login;

              this.router.navigate([], { queryParams: params });
            }

            if (item == 'register') {
              if (this.isLoggedIn) {
                this.notificationService.handle({
                  type: 'info',
                  message: 'You cannot register a new account while signed in'
                });
              } else {
                window.postMessage({
                  type: 'OpenLoginRegisterModal',
                  transition: 'Register'
                }, window.location.href);
              }

              delete params.register;

              this.router.navigate([], { queryParams: params });
            }
          });
        });
      }

      if (event instanceof NavigationStart) {
        // whenever a new navigation occurs the scroll needs to be enabled by default. this covers some edge cases found on mobile
        // in certain cases we have modals open, which, coupled with navigation without closing those modals leads to situations where 
        // the scroll remains disabled on the newly opened page
        window.postMessage({ type: 'EnableScroll'}, window.location.href);

        // @TODO this should be handled by app-routing.module and not there
        // but the entire zone of app-routing asks for a small refactor to be able to handle everything well
        if (environment.maintenance && event.url != '/500-server-error') {
          this.router.navigate([`/${this.config.language}/500-server-error`]);
        }

        if (this.authService.getSessionID() != null) {
          this.authService.checkSession();
        }

        const urlSplit = event.url.split('/');

        if (urlSplit[1] == 'email-verification' || urlSplit[1] == 'forgot-password') {
          urlSplit[2] = urlSplit[2] ? urlSplit[2] : '';
          this.router.navigate([`/${this.config.language}/${urlSplit[1]}/${urlSplit[2]}`]);
        }

        // Handles the case when the users hits a path without language in URL
        switch (event.url) {
          case '/casino':
            this.router.navigate([`/${this.config.language}/casino`]);
            break;
          case '/sports':
            this.router.navigate([`/${this.config.language}/sports`]);
            break;
          case '/sport':
            this.router.navigate([`/${this.config.language}/sport`]);
            break;
          case '/live-casino':
            this.router.navigate([`/${this.config.language}/live-casino`]);
            break;
          case '/virtuals':
            this.router.navigate([`/${this.config.language}/virtuals`]);
            break;
          case '/tournaments':
            this.router.navigate([`/${this.config.language}/tournaments`]);
            break;
          case '/promotions':
            this.router.navigate([`/${this.config.language}/promotions`]);
            break;
          case '/wheel-of-fortune':
            this.router.navigate([`/${this.config.language}/wheel-of-fortune`]);
            break;
          case '/home':
            this.router.navigate([`/${this.config.language}/home`]);
            break;
        }
      }

      // This is needed in order to set the pagetag and positiontag for the bannermatrix according to the active page we are on
      switch (this.router.url.split('/')[2]) {
        case 'casino':
          this.config.bannermatrix.pagetag = this.config.casinoLobby.pagetag;
          this.config.bannermatrix.positiontag = this.config.casinoLobby.positiontag;
          break;

        case 'live-casino':
          this.config.bannermatrix.pagetag = this.config.liveCasinoLobby.pagetag;
          this.config.bannermatrix.positiontag = this.config.liveCasinoLobby.positiontag;
          break;

        case 'virtuals':
          this.config.bannermatrix.pagetag = this.config.virtualSportsLobby.pagetag;
          this.config.bannermatrix.positiontag = this.config.virtualSportsLobby.positiontag;
          break;

        case 'tournaments':
          this.config.bannermatrix.pagetag = this.config.tournamentsLobby.pagetag;
          this.config.bannermatrix.positiontag = this.config.tournamentsLobby.positiontag;
          break;

        case 'promotions':
          this.config.bannermatrix.pagetag = this.config.promotions.pagetag;
          this.config.bannermatrix.positiontag = this.config.promotions.positiontag;
          break;
        
        case 'home':
          this.config.bannermatrix.pagetag = this.config.homePage.pagetag;
          this.config.bannermatrix.positiontag = this.config.homePage.positiontag;
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd) // Only act on NavigationEnd events
    ).subscribe(() => {
      for (const key of Object.keys(MetadataConfig)) {
        if (key === this.router.url) {
          this.metaService.updateTag({ name: 'title', content: MetadataConfig[key].title });
          this.metaService.updateTag({ name: 'description', content: MetadataConfig[key].description });
          this.metaService.updateTag({ name: 'keywords', content: MetadataConfig[key].keywords });
        }
      }
    });
    
    this.showBanners = true;
  }
}
