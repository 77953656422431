export const customStyling:string = `

.LoadingIndicator{
    border:solid 10px  rgb(208, 4, 108);
    border-radius: 50%;
	border-right-color: transparent;
	border-bottom-color: transparent;
}

.LimitSubmitButton:disabled{
    background-color: #D0046C;
    opacity: 0.3;
}

.LimitSubmitButton{
    background-color: rgb(208, 4, 108);
}
`;
