<casino-lobby
  [attr.session]="sessionID"
  [attr.userid]="userID"
  [attr.endpoint]="virtualSportsConfig.endpoint"
  [attr.datasource]="virtualSportsConfig.datasource"
  [attr.winnersdatasources]="virtualSportsConfig.winnersdatasources"
  [attr.lang]="virtualSportsConfig.lang"
  [attr.mostplayed]="virtualSportsConfig.mostPlayed"
  [attr.mostplayedrounds]="virtualSportsConfig.mostplayedrounds"
  [attr.lastplayed]="virtualSportsConfig.lastplayed"
  [attr.lastplayedperiod]="virtualSportsConfig.lastplayedperiod"
  [attr.mostplayedperiod]="virtualSportsConfig.mostplayedperiod"
  [attr.favorites]="virtualSportsConfig.favorites"
  [attr.loginevent]="virtualSportsConfig.loginEvent"
  [attr.registerevent]="virtualSportsConfig.registerEvent"
  [attr.depositevent]="virtualSportsConfig.depositEvent"
  [attr.clockformat]="virtualSportsConfig.clockformat"
  [attr.lobbyid]="virtualSportsConfig.lobbyID"
  [attr.containermaxwidth]="virtualSportsConfig.containermaxwidth"
  [attr.haspanicbutton]="virtualSportsConfig.haspanicbutton"
  [attr.activecategory]="virtualSportsConfig.activecategory"
  [attr.actionevent]="virtualSportsConfig.actionevent"
  [attr.gamemodal]="virtualSportsConfig.gamemodal"
  [attr.playrandomgame]="virtualSportsConfig.randomgame"
  [attr.playforfun]="virtualSportsConfig.playforfun"
  [attr.visiblegames]="virtualSportsConfig.visiblegames"
  [attr.integratedgameframedesktop]="virtualSportsConfig.integratedgameframedesktop"
  [attr.integratedgameframemobile]="virtualSportsConfig.integratedgameframemobile"
  [attr.gamepagemodalurl]="virtualSportsConfig.gamepagemodalurl"
  [attr.clientstyling]="virtualSportsConfig.clientstyling"
  [attr.addfilterselector]="virtualSportsConfig.addfilterselector"
  [attr.addsortingselector]="virtualSportsConfig.addsortingselector"
  [attr.filterbylogo]="virtualSportsConfig.filterbylogo"
  [attr.casinogamesgridslider]="virtualSportsConfig.casinogamesgridslider"
></casino-lobby>
