import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AuthService } from './services/auth.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { NotificationService } from './services/notification.service';
import { CmsService } from './services/cms.service';
import { ExternalScriptInjectorService } from './services/external-script-injector.service';

import { LoginComponent } from './states/login/login.component';
import { SportsComponent } from './states/sports/sports.component';
import { GamePageComponent } from './states/game-page/game-page.component';
import { NotFoundComponent } from './states/not-found/not-found.component';
import { RegisterComponent } from './states/register/register.component';
import { LiveLobbyComponent } from './states/live-lobby/live-lobby.component';
import { PromotionsComponent } from './states/promotions/promotions.component';
import { CasinoLobbyComponent } from './states/casino-lobby/casino-lobby.component';
import { ServerErrorComponent } from './states/server-error/server-error.component';
import { PlayerProfileComponent } from './states/player-profile/player-profile.component';
import { NotAuthorizedComponent } from './states/not-authorized/not-authorized.component';
import { ForgotPasswordComponent } from './states/forgot-password/forgot-password.component';
import { TournamentPageComponent } from './states/tournament-page/tournament-page.component';
import { TournamentsLobbyComponent } from './states/tournaments-lobby/tournaments-lobby.component';
import { EmailVerificationComponent } from './states/email-verification/email-verification.component';
import { VirtualsSportsLobbyComponent } from './states/virtuals-sports-lobby/virtuals-sports-lobby.component';
import { CategoriesComponent } from './states/categories/categories.component';
import { HomePageComponent } from './states/home-page/home-page.component';

import { TournamentsMyLobbyComponent } from './states/tournaments-my-lobby/tournaments-my-lobby.component';
import { GamePageIntegratedComponent } from './states/game-page-integrated/game-page-integrated.component';

import { HotToastModule } from '@ngneat/hot-toast';
import { WheelOfFortuneComponent } from './states/wheel-of-fortune/wheel-of-fortune.component';
import { LoginRegisterControllerComponent } from './states/login-register-controller/login-register-controller.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeToggleComponent } from './components/theme-toggle/theme-toggle.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SportsComponent,
    RegisterComponent,
    GamePageComponent,
    NotFoundComponent,
    LiveLobbyComponent,
    PromotionsComponent,
    ServerErrorComponent,
    CasinoLobbyComponent,
    NotAuthorizedComponent,
    PlayerProfileComponent,
    TournamentPageComponent,
    TournamentsLobbyComponent,
    EmailVerificationComponent,
    GamePageIntegratedComponent,
    VirtualsSportsLobbyComponent,
    ForgotPasswordComponent,
    TournamentsMyLobbyComponent,
    CategoriesComponent,
    WheelOfFortuneComponent,
    LoginRegisterControllerComponent,
    HomePageComponent,
    ThemeToggleComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HotToastModule.forRoot(),
    // ngx-translate and the loader module
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  providers: [
    AuthService,
    GoogleAnalyticsService,
    NotificationService,
    CmsService,
    ExternalScriptInjectorService,
    Meta
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
