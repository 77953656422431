<casino-tournaments-page-controller
  [attr.session]="sessionID"
  [attr.userid]="userID"
  [attr.currency]="currency"
  [attr.tourid]="pageConfig.tourID"
  [attr.lang]="pageConfig.lang"
  [attr.endpoint]="pageConfig.endpoint"
  [attr.cmsendpoint]="pageConfig.cmsEndpoint"
  [attr.showleaderboard]="pageConfig.showLeaderboard"
  [attr.tablelimit]="pageConfig.tablelimit"
  [attr.keepbrowsercontext]="pageConfig.keepBrowserContext"
  [attr.viewmode]="pageConfig.viewmode"
  [attr.clientstyling]="pageConfig.style"
>
</casino-tournaments-page-controller>
